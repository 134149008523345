import { Injectable } from "@angular/core";
import { HttpService } from "./common/http.service";
import { ChucVu } from "../models/chuc-vu.model";
import { AppConfigModel } from "app/models/config/app-config.model";
import { HttpClient } from "@angular/common/http";
import { Criteria } from "../models/common/criteria";

@Injectable({
  providedIn: "root"
})
export class ChucVuService {

  constructor(
    private _httpService: HttpService,
    private http: HttpClient
  ) { }

  async saveData(model: ChucVu) {
    const url = "/ChucVu/insert";
    return this._httpService.post(url, model).toPromise();
  }

  async getAll() {
    const url = "/ChucVu/get-all";
    const resp = await this._httpService.get(url).toPromise();
    return resp.data;
  }

  // update
  async updateData(model: ChucVu) {
    const url = "/ChucVu/update";
    return this._httpService.post(url, model).toPromise();
  }

  // delete
  async delete(model: ChucVu) {
    const url = "/ChucVu/delete";
    return this._httpService.post(url, model).toPromise();
  }

  async deletes(model: any) {
    const url = "/ChucVu/deletes";
    return this._httpService.post(url, model).toPromise();
  }

  async filter(criteria: Criteria) {
    const url = "/ChucVu/filter";
    const resp = await this._httpService.post(url, criteria).toPromise();
    return resp.data;
  }
}
