declare var jQuery: any;
import { Component, OnInit, AfterViewInit } from "@angular/core";
import { KhenThuong } from "../../models/khen-thuong.model";
import { LoaiKhenThuong } from "../../models/loai-khen-thuong.model";
import { KhenThuongService } from "../../services/khen-thuong.service";
import { LoaiKhenThuongService } from "../../services/loai-khen-thuong.service";
import { MatDialogConfig, MatDialog } from "@angular/material/dialog";
import { KhenThuongModalComponent } from "../khen-thuong-modal/khen-thuong-modal.component";
import { Criteria } from "../../models/common/criteria";

@Component({
  selector: "app-khen-thuong",
  templateUrl: "./khen-thuong.component.html",
  styleUrls: ["./khen-thuong.component.scss"]
})
export class KhenThuongComponent implements OnInit, AfterViewInit {

  public detailData: Array<KhenThuong>;
  public loaiKhenThuong: Array<LoaiKhenThuong>;
  public listDelete = [];
  /**
      * update
   */
  public filter: Criteria;
  public selectAllRow = false;
  ngAfterViewInit(): void {
  }

  constructor(
    public services: KhenThuongService,
    public loaiKhenThuongService: LoaiKhenThuongService,
    public dialog: MatDialog
  ) {
    this.filter = new Criteria();
    this.detailData = new Array<KhenThuong>();
  }

  async ngOnInit() {
    await this.reloadData();

  }


  async delete(model: KhenThuong): Promise<void> {
    if (confirm("Bạn muốn xóa bản ghi này")) {
      const resq = await this.services.delete(model);
      await this.reloadData();
    }
  }

  add(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.hasBackdrop = false;
    dialogConfig.width = "900px";
    const dialogRef = this.dialog.open(KhenThuongModalComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(async result => {
      if (result === true) {
        this.add();
      } else {
        await this.reloadData();
      }
    });
  }

  update(model: KhenThuong): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.hasBackdrop = false;
    dialogConfig.width = "900px";
    dialogConfig.data = model;
    const dialogRef = this.dialog.open(KhenThuongModalComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(async result => {
      if (result === true) {
        this.add();
      } else {
        await this.reloadData();
      }
    });
  }
  selectDelete(item) {
    const index = this.listDelete.findIndex(x => x.maKhenThuong === item.maKhenThuong);
    if (index > -1) {
      this.listDelete.splice(index, 1);
    } else {
      this.listDelete.push(item);
    }
  }
  async deleteList() {
    if (confirm("Bạn muốn xóa danh sách bản ghi này")) {
      const resq = await this.services.deletes(this.listDelete);
      await this.reloadData();
    }
  }

  /**
   * update
   */

  async checkAll() {
    this.selectAllRow = !this.selectAllRow;
    if (this.detailData) {
      this.detailData.forEach(p => p.selected = this.selectAllRow);
    }
  }

  async changeCheck(idx) {
    if (this.detailData[idx]) {
      this.detailData[idx].selected = !this.detailData[idx].selected;
      this.selectAllRow = this.detailData.filter(p => p.selected).length === this.detailData.length;
      debugger
    }
  }

  async changeFilterPage(event: number) {
    this.filter.page.offset = event;
    await this.reloadData();
  }

  async changeFilter(fieldName: string, event: any) {
    if (!event || !event.value || !fieldName || !Boolean(this.filter[fieldName])) {
      return;
    }
    this.filter[fieldName] = event.value;
    await this.reloadData();
  }

  async reloadData() {
    const data = await this.services.filter(this.filter);
    if (data == null) {
      return;
    }
    this.detailData = data.rows.map(p => Object.assign(new KhenThuong(), p));

    this.loaiKhenThuong = await this.loaiKhenThuongService.getAll();

    this.detailData.forEach((p) => {
      const ldv = this.loaiKhenThuong.find(x => x.maLoaiKhenThuong === p.maLoaiKhenThuong);
      if (ldv) {
        p.tenLoaiKhenThuong = ldv.tenLoaiKhenThuong;
      }
    });
    this.filter.page = data.page;
  }
}
