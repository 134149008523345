import { Injectable } from "@angular/core";
import { HttpService } from "./common/http.service";
import { QuyetDinhKhenThuong } from "../models/quyet-dinh-khen-thuong/quyet-dinh-khen-thuong.model";
import { QuyetDinhKhenThuongCriteria } from "app/modules/admin/models/quyet-dinh-khen-thuong/quyet-dinh-khen-thuong.criteria";
import { AppConfigModel } from "app/models/config/app-config.model";

@Injectable({
    providedIn: "root"
})
export class QuyetDinhKhenThuongService {

    constructor(
        private _httpService: HttpService,
    ) { }

    async saveData(model: QuyetDinhKhenThuong) {
        const url = "/QuyetDinhKhenThuong/insert";
        return this._httpService.post(url, model).toPromise();

        // return await new Promise<any>((resolve, reject) => {
        //   this.http.post(url, model).subscribe((response: any) => {
        //     // const myObject: QuyetDinhKhenThuong[] = response.data;
        //     resolve(response);
        //   });
        // });

    }

    async getAll() {
        const url = "/QuyetDinhKhenThuong/get-all";
        const resp = this._httpService.get(url).toPromise();
        const data = await resp;
        return data.data;
    }

    // update
    async updateData(model: QuyetDinhKhenThuong) {
        const url = "/QuyetDinhKhenThuong/update";
        return this._httpService.post(url, model).toPromise();
    }

    // delete
    async delete(model: QuyetDinhKhenThuong) {
        const url = "/QuyetDinhKhenThuong/delete";
        return this._httpService.post(url, model).toPromise();

    }

    async filter(criteria: QuyetDinhKhenThuongCriteria) {
        const url = "/QuyetDinhKhenThuong/filter";
        const resp = await this._httpService.post(url, criteria).toPromise();
        return resp.data;
    }

    async export(criteria: QuyetDinhKhenThuongCriteria) {
        const url = "/QuyetDinhKhenThuong/export";
        const resp = await this._httpService.post(url, criteria).toPromise();
        return resp.data;
    }

    upload(model: any) {
        return new Promise(function (resolve, reject) {
            const createUrl = AppConfigModel.ApiConfig.url + "/QuyetDinhKhenThuong/upload";

            const xhr = new XMLHttpRequest();
            xhr.withCredentials = false;
            xhr.open("POST", createUrl, true);
            xhr.onload = function () {
                if (this.status >= 200 && this.status < 300) {
                    resolve(JSON.parse(xhr.response));
                } else {
                    reject(JSON.parse(xhr.response));
                }
            };
            xhr.onerror = function () {
                reject(JSON.parse(xhr.response));
            };

            xhr.send(model);

        });

    }

    importExcel(model: any) {
        return new Promise(function (resolve, reject) {
            const createUrl = AppConfigModel.ApiConfig.url + "/QuyetDinhKhenThuong/import-template";

            const xhr = new XMLHttpRequest();
            xhr.withCredentials = false;
            xhr.open("POST", createUrl, true);
            xhr.onload = function () {
                if (this.status >= 200 && this.status < 300) {
                    resolve(JSON.parse(xhr.response));
                } else {
                    reject(JSON.parse(xhr.response));
                }
            };
            xhr.onerror = function () {
                reject(JSON.parse(xhr.response));
            };

            xhr.send(model);

        });
    }

    async exportTemplate(number: number) {
        const url = "/QuyetDinhKhenThuong/export-template";
        const resp = await this._httpService.post(url, number).toPromise();
        return resp.data;
    }

    async deletes(model: any) {
        const url = "/QuyetDinhKhenThuong/deletes";
        return this._httpService.post(url, model).toPromise();
      }

}
