<div class="row">
    <div class="col-md-12">
        <div class="card">
            <form class="form-horizontal">
                <div class="card-body">
                    <h4 class="card-title">{{title}}</h4>                    
                    <div class="form-group row">
                        <label for="lname" class="col-sm-3 text-right control-label col-form-label">Tên hình thức khen thưởng</label>
                        <div class="col-sm-9">
                            <input type="text" class="form-control" name="lname" [(ngModel)]="detailData.tenHinhThuc" placeholder="Nhập hình thức khen thưởng">
                        </div>
                    </div>
                   



                </div>
                <div class="border-top">
                        <div class="card-body float-right">
                                <button type="button" class="btn btn-primary" (click)="save(true)">Lưu và thêm</button>
                                &nbsp;
                              <button type="button" class="btn btn-primary" (click)="save(false)">Lưu</button>
                              &nbsp;
                              <button type="button" class="btn btn-secondary" mat-dialog-close>Hủy</button>
                        </div>
                    </div>
            </form>
        </div>
    </div>          
</div>