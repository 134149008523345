import { Injectable } from "@angular/core";
import { CapKhenThuong } from "../models/cap-khen-thuong.model";
import { HttpService } from "./common/http.service";
import { Criteria } from "../models/common/criteria";

@Injectable({
  providedIn: "root"
})
export class CapKhenThuongService {

  constructor(
    private _httpService: HttpService,
  ) { }

  async saveData(model: CapKhenThuong) {
    const url = "/CapKhenThuong/insert";
    return this._httpService.post(url, model).toPromise();
  }

  async getAll() {
    const url = "/CapKhenThuong/get-all";
    const resp = await this._httpService.get(url).toPromise();
    return resp.data;
  }

  // update
  async updateData(model: CapKhenThuong) {
    const url = "/CapKhenThuong/update";
    return this._httpService.post(url, model).toPromise();
  }

  // delete
  async delete(model: CapKhenThuong) {
    const url = "/CapKhenThuong/delete";
    return this._httpService.post(url, model).toPromise();
  }

  async deletes(model: any) {
    const url = "/CapKhenThuong/deletes";
    return this._httpService.post(url, model).toPromise();
  }

  async filter(criteria: Criteria) {
    const url = "/CapKhenThuong/filter";
    const resp = await this._httpService.post(url, criteria).toPromise();
    return resp.data;
  }
}
