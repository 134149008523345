declare var jQuery: any;
import { Component, OnInit, AfterViewInit } from "@angular/core";
import { UserModel } from "../../models/user/user.model";
import { QuanLyTaiKhoanService } from "../../services/quan-ly-tai-khoan.service";
import { QuanLyTaiKhoanModalComponent } from "../quan-ly-tai-khoan-modal/quan-ly-tai-khoan-modal.component";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { Criteria } from "../../models/common/criteria";

@Component({
  selector: "app-quan-ly-tai-khoan",
  templateUrl: "./quan-ly-tai-khoan.component.html",
  styleUrls: ["./quan-ly-tai-khoan.component.scss"]
})
export class QuanLyTaiKhoanComponent implements OnInit, AfterViewInit {

  public detailData: Array<UserModel>;
  public listDelete = [];
  public roleAdmin = "Quản trị viên";
  public roleMember = "Thành viên";
  /**
      * update
   */
  public filter: Criteria;
  public selectAllRow = false;

  ngAfterViewInit(): void {
  }

  constructor(
    public services: QuanLyTaiKhoanService,
    public dialog: MatDialog
  ) {
    this.filter = new Criteria();
    this.detailData = new Array<UserModel>();
  }

  async ngOnInit() {
    await this.reloadData();
  }



  async delete(model: UserModel): Promise<void> {
    if (confirm("Bạn muốn xóa bản ghi này")) {
      const resq = await this.services.delete(model);
      await this.reloadData();
    }
  }

  add(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.hasBackdrop = false;
    dialogConfig.width = "900px";
    const dialogRef = this.dialog.open(QuanLyTaiKhoanModalComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(async result => {
      if (result === true) {
        this.add();
      } else {
        await this.reloadData();
      }
    });
  }

  update(model: UserModel): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.hasBackdrop = false;
    dialogConfig.width = "900px";
    dialogConfig.data = model;
    const dialogRef = this.dialog.open(QuanLyTaiKhoanModalComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(async result => {
      if (result === true) {
        this.add();
      } else {
        await this.reloadData();
      }
    });
  }

  selectDelete(item) {
    const index = this.listDelete.findIndex(x => x.id === item.id);
    if (index > -1) {
      this.listDelete.splice(index, 1);
    } else {
      this.listDelete.push(item);
    }
  }
  async deleteList() {
    if (confirm("Bạn muốn xóa danh sách bản ghi này")) {
      const resq = await this.services.deletes(this.listDelete);
      await this.reloadData();
    }
  }

  /**
  * update
  */

  async checkAll() {
    this.selectAllRow = !this.selectAllRow;
    if (this.detailData) {
      this.detailData.forEach(p => p.selected = this.selectAllRow);
    }
  }

  async changeCheck(idx) {
    if (this.detailData[idx]) {
      this.detailData[idx].selected = !this.detailData[idx].selected;
      this.selectAllRow = this.detailData.filter(p => p.selected).length === this.detailData.length;
      debugger
    }
  }

  async changeFilterPage(event: number) {
    this.filter.page.offset = event;
    await this.reloadData();
  }

  async changeFilter(fieldName: string, event: any) {
    if (!event || !event.value || !fieldName || !Boolean(this.filter[fieldName])) {
      return;
    }
    this.filter[fieldName] = event.value;
    await this.reloadData();
  }

  async reloadData() {
    const data = await this.services.filter(this.filter);
    if (data == null) {
      return;
    }
    this.detailData = data.rows.map(p => Object.assign(new UserModel(), p));
    this.detailData.forEach(p => {
      if (p.roleId === 1) {
        p.roleName = this.roleAdmin;
      } else {
        p.roleName = this.roleMember;
      }
    });
    this.filter.page = data.page;
  }
}
