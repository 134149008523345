import { SelectModel } from "app/models/common/select.model";

export class QuyetDinhKhenThuong extends SelectModel {
    // maDoiTuong: number;
    // ten: string;
    // diaChi: string;
    // maChucVu: number;
    // maChucDanh: number;
    // maDonVi: number;
    // quocTich: string;
    // ngayQuyetDinh: Date;
    // namKhen: number;
    // maHinhThucKT: number;
    // maPhuongThucKT: number;
    // ghiChu: string;
    // maQD: number;
    // maLinhVucHoatDong: number;
    // maTinhChatTang: number;
    // thanhTich: string;
    maQD: number;
    maDoiTuong: number;
    maDanhXung: number;
    ten: string;
    diaChi: string;
    maChucVu: number;
    maLoaiCanBo: number;
    maDonVi: number;
    maTinh: string;
    maHuyen: string;
    maXa: string;
    soQuyetDinh: string;
    ngayQuyetDinh: Date;
    namKhen: number;
    maCap: number;
    maHinhThuc: number;
    maKhenThuong: number;
    maMucGiaiThuong: number;
    soDinhDanh: string;
    ngaySinh: Date;
    ghiChu: string;
    // custom property
    tenDoiTuong: string;
    tenDanhXung: string;
    tenChucVu: string;
    tenLoaiCanBo: string;
    tenDonVi: string;
    tenTinh: string;
    tenHuyen: string;
    tenXa: string;
    tenCap: string;
    tenHinhThuc: string;
    tenKhenThuong: string;
    tenMucGiaiThuong: string;
    attachment: string;
    readOnly = false;
}
