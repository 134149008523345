import { Injectable } from '@angular/core';
import { UserLoginModel } from 'app/modules/admin/models/user/user-login.model';
import { HttpService } from 'app/modules/admin/services/common/http.service';
import { AuthService } from 'app/modules/admin/services/common/auth.service';
import AuthRoutes from 'app/modules/admin/routes/auth.routes';
import { Router } from '@angular/router';

@Injectable()
// TODO: Bỏ nó đi, cần ở đâu inject ở đó
export class LoginService {
  constructor(
    private _httpService: HttpService,
    private _authService: AuthService,
    private _router: Router,
  ) { }

  // Đừng truyền thế này, bỏ vào 1 cái model cho anh
  async login(model: UserLoginModel) {
    const url = AuthRoutes.LOGIN;
    const result = await this._httpService.post(url, model).toPromise();
    if (result.isSuccess) {
      this._authService.setToken(result.data);
    }
    return result;
  }

  async logout() {
    this._authService.cleanTokens();
    this._router.navigate(['/login']);
  }
}
