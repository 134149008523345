import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import AuthRoutes from '../../routes/auth.routes';

import { HttpClient } from '@angular/common/http';
import { AppConfigModel } from 'app/models/config/app-config.model';
import { TokenModel } from 'app/models/common/token.model';
import { UserModel } from 'app/modules/admin/models/user/user.model';

@Injectable()
export class AuthService {
    private localStorageKeys = {
        user_info: 'user_info',
        access_token: 'access_token'
    };

    private jwtHelper = new JwtHelperService();
    public constructor(private http: HttpClient) {

    }
    public isAuthenticated() {
        try {
            const token = this.getAccessToken();
            if (!token || this.jwtHelper.isTokenExpired(token)) {
                return false;
            } else {
                return true;
            }
        } catch (ex) {
            return false;
        }
    }

    public setToken(token: TokenModel) {
        this.setLocalStorage(this.localStorageKeys.access_token, token.accessToken);
        this.setLocalStorage(this.localStorageKeys.user_info, JSON.stringify(token.additionalData['user_info']));
    }

    public getAccessToken() {
        return this.getLocalStorage(this.localStorageKeys.access_token);
    }

    public getUserInfo(): UserModel {
        return JSON.parse(this.getLocalStorage(this.localStorageKeys.user_info));
    }

    public async hasFunction(functions: any) {
        const systemFunctions = JSON.parse(localStorage['Functions']);
        let flag = false;
        functions.forEach(element => {
            systemFunctions.forEach(oj => {
                if (oj.functionId === element) {
                    flag = true;
                    return;
                }
            });
        });
        return flag;
    }

    public cleanTokens(): void {
        Object.keys(this.localStorageKeys).forEach(p => this.removeLocalStorage(p));
    }

    private setLocalStorage(key: string, value: string) {
        localStorage.setItem(key, value);
    }

    private getLocalStorage(key: string) {
        return localStorage.getItem(key) || null;
    }

    private removeLocalStorage(key: string) {
        localStorage.removeItem(key);
    }

}
