import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { QuanLyTaiKhoanService } from '../../services/quan-ly-tai-khoan.service';
import { UserModel } from '../../models/user/user.model';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  public title = 'Thay đổi mật khẩu';
  public confirmPass: string;

  constructor(
    public dialogRef: MatDialogRef<ChangePasswordComponent>,
    public services: QuanLyTaiKhoanService,
    @Inject(MAT_DIALOG_DATA) public data: UserModel
  ) { }

  ngOnInit() {
  }

  async save() {
    if ( this.data.password === this.confirmPass) {
      this.services.changePassWord(this.data);
      alert("Đổi mật khẩu thành công");
      this.dialogRef.close();
    }
    else {
      alert('Nhập lại mật khẩu không trùng khớp');
    }
   
  }

}
