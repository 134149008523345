declare var jQuery: any;
import { Component, OnInit, AfterViewInit } from "@angular/core";
import { DonVi } from "../../models/don-vi.model";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { DonViService } from "../../services/don-vi.service";
import { SubdivisionsComponent } from "../subdivisions/subdivisions.component";
import { LoaiDonVi } from "../../models/loai-don-vi.model";
import { LoaiDonViService } from "../../services/loai-don-vi.service";
import { Criteria } from "../../models/common/criteria";

@Component({
  selector: "app-don-vi",
  templateUrl: "./don-vi.component.html",
  styleUrls: ["./don-vi.component.scss"]
})
export class DonViComponent implements OnInit, AfterViewInit {

  public detailData: Array<DonVi>;
  public loaiDonVi: Array<LoaiDonVi>;
  public listDelete = [];
  /**
       * update
    */
  public filter: Criteria;
  public selectAllRow = false;

  ngAfterViewInit(): void {
  }

  constructor(
    public services: DonViService,
    public loaidDonViService: LoaiDonViService,
    public dialog: MatDialog
  ) {
    this.filter = new Criteria();
    this.detailData = new Array<DonVi>();
  }

  async ngOnInit() {
    this.loaiDonVi = await this.loaidDonViService.getAll();
    await this.reloadData();
  }


  async delete(model: DonVi): Promise<void> {
    if (confirm("Bạn muốn xóa bản ghi này")) {
      const resq = await this.services.delete(model);
      await this.reloadData();
    }
  }

  add(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.hasBackdrop = false;
    dialogConfig.width = "900px";
    const dialogRef = this.dialog.open(SubdivisionsComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(async result => {
      if (result === true) {
        this.add();
      } else {
        await this.reloadData();
      }
    });
  }

  update(model: DonVi): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.hasBackdrop = false;
    dialogConfig.width = "900px";
    dialogConfig.data = model;
    const dialogRef = this.dialog.open(SubdivisionsComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(async result => {
      if (result === true) {
        this.add();
      } else {
        await this.reloadData();
      }
    });
  }
  selectDelete(item) {
    const index = this.listDelete.findIndex(x => x.maDonVi === item.maDonVi);
    if (index > -1) {
      this.listDelete.splice(index, 1);
    } else {
      this.listDelete.push(item);
    }
  }
  async deleteList() {
    if (confirm("Bạn muốn xóa danh sách bản ghi này")) {
      const resq = await this.services.deletes(this.listDelete);
      await this.reloadData();
    }
  }

  /**
   * update
   */

  async checkAll() {
    this.selectAllRow = !this.selectAllRow;
    if (this.detailData) {
      this.detailData.forEach(p => p.selected = this.selectAllRow);
    }
  }

  async changeCheck(idx) {
    if (this.detailData[idx]) {
      this.detailData[idx].selected = !this.detailData[idx].selected;
      this.selectAllRow = this.detailData.filter(p => p.selected).length === this.detailData.length;
      debugger
    }
  }

  async changeFilterPage(event: number) {
    this.filter.page.offset = event;
    await this.reloadData();
  }

  async changeFilter(fieldName: string, event: any) {
    if (!event || !event.value || !fieldName || !Boolean(this.filter[fieldName])) {
      return;
    }
    this.filter[fieldName] = event.value;
    await this.reloadData();
  }

  async reloadData() {
    const data = await this.services.filter(this.filter);
    if (data == null) {
      return;
    }
    this.detailData = data.rows.map(p => Object.assign(new DonVi(), p));
    this.detailData.forEach((p) => {
      const ldv = this.loaiDonVi.find(x => x.maLoaiDonVi === p.maLoaiDonVi);
      if (ldv) {
        p.tenLoaiDonVi = ldv.tenLoaiDonVi;
      }
    });

    this.filter.page = data.page;
  }
}
