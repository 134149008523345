declare var jQuery: any;
import { Component, OnInit, AfterViewInit } from "@angular/core";
import { CapKhenThuong } from "../../models/cap-khen-thuong.model";
import { CapKhenThuongService } from "../../services/cap-khen-thuong.service";
import { MatDialogConfig, MatDialog } from "@angular/material/dialog";
import { CapKhenThuongModalComponent } from "../cap-khen-thuong-modal/cap-khen-thuong-modal.component";
import { Criteria } from "../../models/common/criteria";

@Component({
  selector: "app-cap-khen-thuong",
  templateUrl: "./cap-khen-thuong.component.html",
  styleUrls: ["./cap-khen-thuong.component.scss"]
})
export class CapKhenThuongComponent implements OnInit, AfterViewInit {

  public detailData: Array<CapKhenThuong>;
  public listDelete = [];

  /**
    * update
 */
  public filter: Criteria;
  public selectAllRow = false;

  ngAfterViewInit(): void {
  }

  constructor(
    public services: CapKhenThuongService,
    public dialog: MatDialog
  ) {
    this.detailData = new Array<CapKhenThuong>();
    this.filter = new Criteria();
  }

  async ngOnInit() {
    await this.reloadData();
  }


  async delete(model: CapKhenThuong): Promise<void> {
    if (confirm("Bạn muốn xóa bản ghi này")) {
      const resq = await this.services.delete(model);
      await this.reloadData();
    }
  }

  add(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.hasBackdrop = false;
    dialogConfig.width = "900px";
    const dialogRef = this.dialog.open(CapKhenThuongModalComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(async result => {
      if (result === true) {
        this.add();
      } else {
        await this.reloadData();
      }
    });
  }

  update(model: CapKhenThuong): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.hasBackdrop = false;
    dialogConfig.width = "900px";
    dialogConfig.data = model;
    const dialogRef = this.dialog.open(CapKhenThuongModalComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(async result => {
      if (result === true) {
        this.add();
      } else {
        await this.reloadData();
      }
    });
  }

  selectDelete(item) {
    const index = this.listDelete.findIndex(x => x.maCap === item.maCap);
    if (index > -1) {
      this.listDelete.splice(index, 1);
    } else {
      this.listDelete.push(item);
    }
  }
  async deleteList() {
    if (confirm("Bạn muốn xóa danh sách bản ghi này")) {
      const resq = await this.services.deletes(this.listDelete);
      await this.reloadData();
    }
  }

  /**
   * update
   */

  async checkAll() {
    this.selectAllRow = !this.selectAllRow;
    if (this.detailData) {
      this.detailData.forEach(p => p.selected = this.selectAllRow);
    }
  }

  async changeCheck(idx) {
    if (this.detailData[idx]) {
      this.detailData[idx].selected = !this.detailData[idx].selected;
      this.selectAllRow = this.detailData.filter(p => p.selected).length === this.detailData.length;
      debugger
    }
  }

  async changeFilterPage(event: number) {
    this.filter.page.offset = event;
    await this.reloadData();
  }

  async changeFilter(fieldName: string, event: any) {
    if (!event || !event.value || !fieldName || !Boolean(this.filter[fieldName])) {
      return;
    }
    this.filter[fieldName] = event.value;
    await this.reloadData();
  }

  async reloadData() {
    const data = await this.services.filter(this.filter);
    if (data == null) {
      return;
    }
    this.detailData = data.rows.map(p => Object.assign(new CapKhenThuong(), p));
    this.filter.page = data.page;
  }

}
