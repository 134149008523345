import { Injectable } from '@angular/core';
import { Huyen } from '../models/Huyen/huyen.model';
import { HttpService } from './common/http.service';

@Injectable({
  providedIn: 'root'
})
export class HuyenService {

  constructor(
    private _httpService: HttpService,
  ) { }

  async saveData(model: Huyen) {
    const url = '/Huyen/insert';
    return this._httpService.post(url, model).toPromise();
  }

  async getAll() {
    const url = '/Huyen/get-all';
    const resp = await this._httpService.get(url).toPromise();
    return resp.data;
  }

  async filter(id: any) {
    const url = '/Huyen/filter';
    const resp = await this._httpService.post(url, id).toPromise();
    return resp.data;
  }
}
