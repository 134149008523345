declare var jQuery: any;
import { Component, OnInit, AfterViewInit } from "@angular/core";
import { QuyetDinhKhenThuongService } from "../../services/quyet-dinh-khen-thuong.service";
import { QuyetDinhKhenThuong } from "../../models/quyet-dinh-khen-thuong/quyet-dinh-khen-thuong.model";
import { DecisionToRewardComponent } from "../decision-to-reward/decision-to-reward.component";
import { MatDialogConfig, MatDialog } from "@angular/material/dialog";
import { DoiTuongService } from "../../services/doi-tuong.service";
import { DoiTuong } from "../../models/doi-tuong.model";
import { DanhXungService } from "../../services/danh-xung.service";
import { DanhXung } from "../../models/danh-xung.model";
import { ChucVu } from "../../models/chuc-vu.model";
import { ChucVuService } from "../../services/chuc-vu.service";
import { LoaiCanBo } from "../../models/loai-can-bo.model";
import { LoaiCanBoService } from "../../services/loai-can-bo.service";
import { DonVi } from "../../models/don-vi.model";
import { DonViService } from "../../services/don-vi.service";
import { TinhService } from "../../services/tinh.service";
import { Tinh } from "../../models/tinh.model";
import { HuyenService } from "../../services/huyen.service";
import { XaService } from "../../services/xa.service";
import { Huyen } from "../../models/Huyen/huyen.model";
import { Xa } from "../../models/Xa/xa.model";
import { CapKhenThuongService } from "../../services/cap-khen-thuong.service";
import { CapKhenThuong } from "../../models/cap-khen-thuong.model";
import { KhenThuongService } from "../../services/khen-thuong.service";
import { MucGiaiThuongService } from "../../services/muc-giai-thuong.service";
import { KhenThuong } from "../../models/khen-thuong.model";
import { MucGiaiThuong } from "../../models/muc-giai-thuong.model";
import { HinhThucKhenThuongService } from "../../services/hinh-thuc-khen-thuong.service";
import { HinhThucKhenThuong } from "../../models/hinh-thuc-khen-thuong.model";
import { QuyetDinhKhenThuongCriteria } from "app/modules/admin/models/quyet-dinh-khen-thuong/quyet-dinh-khen-thuong.criteria";
import { HuyenCriteria } from "../../models/Huyen/HuyenCriteria";
import { XaCriteria } from "../../models/Xa/XaCriteria";
import { AppConfigModel } from "app/models/config/app-config.model";
import { Select2OptionData } from "ng-select2";
import { PageDTO } from "app/modules/admin/models/common/page.model";

@Component({
  selector: "app-emulation-and-reward",
  templateUrl: "./emulation-and-reward.component.html",
  styleUrls: ["./emulation-and-reward.component.scss"]
})
export class EmulationAndRewardComponent implements OnInit {
  public detailData: Array<QuyetDinhKhenThuong>;
  public doiTuong: Array<DoiTuong>;
  public doiTuongOption: Array<Select2OptionData>;
  public danhXung: Array<DanhXung>;
  public danhXungOption: Array<Select2OptionData>;
  public chucVu: Array<ChucVu>;
  public chucVuOption: Array<Select2OptionData>;
  public loaiCanBo: Array<LoaiCanBo>;
  public loaiCanBoOption: Array<Select2OptionData>;
  public donVi: Array<DonVi>;
  public donViOption: Array<Select2OptionData>;
  public tinh: Array<Tinh>;
  public tinhOption: Array<Select2OptionData>;
  public huyen: Array<Huyen>;
  public huyenOption: Array<Select2OptionData>;
  public xa: Array<Xa>;
  public xaOption: Array<Select2OptionData>;
  public capKhenThuong: Array<CapKhenThuong>;
  public capKhenThuongOption: Array<Select2OptionData>;
  public khenThuong: Array<KhenThuong>;
  public khenThuongOption: Array<Select2OptionData>;
  public mucGiaiThuong: Array<MucGiaiThuong>;
  public mucGiaiThuongOption: Array<Select2OptionData>;
  public hinhThucKhenThuong: Array<HinhThucKhenThuong>;
  public hinhThucKhenThuongOption: Array<Select2OptionData>;

  public filter: QuyetDinhKhenThuongCriteria;
  public huyenFilter: HuyenCriteria;
  public xaFilter: XaCriteria;
  public pathDownLoad: string;
  public showAction = true;
  public filesUpload: any;
  // public listDelete = [];

  public selectAllRow = false;
  // public select2OptionDefault: Select2Options;
  // public select2OptionDoiTuong: Select2Options;
  // public select2OptionDanhXung: Select2Options;
  // public select2OptionChucVu: Select2Options;
  // public select2OptionCanBo: Select2Options;
  // public select2OptionDonVi: Select2Options;
  // public select2OptionTinh: Select2Options;
  // public select2OptionHuyen: Select2Options;
  // public select2OptionXa: Select2Options;
  // public select2OptionCapKhenThuong: Select2Options;
  // public select2OptionHinhThuc: Select2Options;
  // public select2OptionMucGiaiThuong: Select2Options;
  // public select2OptionDanhHieu: Select2Options;

  constructor(
    public quyetDinhKhenThuongService: QuyetDinhKhenThuongService,
    public dialog: MatDialog,
    public doiTuongService: DoiTuongService,
    public danhXungService: DanhXungService,
    public chucVuService: ChucVuService,
    public loaiCanBoService: LoaiCanBoService,
    public donViService: DonViService,
    public tinhService: TinhService,
    public huyenService: HuyenService,
    public xaService: XaService,
    public capKhenThuongService: CapKhenThuongService,
    public khenThuongService: KhenThuongService,
    public mucGiaiThuongService: MucGiaiThuongService,
    public hinhThucKhenThuongService: HinhThucKhenThuongService,
  ) {
    this.detailData = new Array<QuyetDinhKhenThuong>();
    this.filter = new QuyetDinhKhenThuongCriteria();
    this.huyenFilter = new HuyenCriteria();
    this.xaFilter = new XaCriteria();
    this.pathDownLoad = AppConfigModel.ApiConfig.server + "/Upload/";
    // this.listDelete = [];

    // this.select2OptionDefault = {
    //     allowClear: true,
    // };
    // this.select2OptionDoiTuong = Object.assign({ placeholder: "Chọn đối tượng" }, this.select2OptionDefault);
    // this.select2OptionDanhXung = Object.assign({ placeholder: "Chọn danh xưng" }, this.select2OptionDefault);
    // this.select2OptionChucVu = Object.assign({ placeholder: "Chọn chức vụ" }, this.select2OptionDefault);
    // this.select2OptionCanBo = Object.assign({ placeholder: "Chọn cán bộ" }, this.select2OptionDefault);
    // this.select2OptionDonVi = Object.assign({ placeholder: "Chọn đơn vị" }, this.select2OptionDefault);
    // this.select2OptionTinh = Object.assign({ placeholder: "Chọn tỉnh" }, this.select2OptionDefault);
    // this.select2OptionHuyen = Object.assign({ placeholder: "Chọn huyện" }, this.select2OptionDefault);
    // this.select2OptionXa = Object.assign({ placeholder: "Chọn xã" }, this.select2OptionDefault);
    // this.select2OptionCapKhenThuong = Object.assign({ placeholder: "Chọn cấp khen thưởng" }, this.select2OptionDefault);
    // this.select2OptionHinhThuc = Object.assign({ placeholder: "Chọn hình thức" }, this.select2OptionDefault);
    // this.select2OptionMucGiaiThuong = Object.assign({ placeholder: "Chọn mục giải thưởng" }, this.select2OptionDefault);
    // this.select2OptionDanhHieu = Object.assign({ placeholder: "Chọn danh hiệu" }, this.select2OptionDefault);
  }

  async ngOnInit() {
    const userInfo: any = JSON.parse(localStorage.getItem("user_info"));
    if (userInfo.roleId && userInfo.roleId === 2) {
      this.showAction = false;
    }

    this.filter.maTinh = AppConfigModel.ApiConfig.tinhLaoCaiId;
    this.filter.namKhen = (new Date()).getFullYear();

    await this.loadDropdownResources();
    await this.reloadData();
    await this.onChangeMaTinh(this.filter.maTinh);
    // for (let i = 0; i <= 30; i++) {
    //     this.namList.push(2000 + i);
    // }
  }
  async onChangeMaTinh(value: string) {
    // console.log("onChangeMaTinh: ", value);
    // console.log("this.filter.maTinh: ", this.filter.maTinh);

    this.filter.maTinh = value;
    var response = new Array<Huyen>();
    if (value !== "-1") {
      this.huyenFilter.maTinh = value;
      response = await this.huyenService.filter(this.huyenFilter);
    }
    this.huyen = response;
  }

  async onChangeMaHuyen(value: string) {
    // console.log("onChangeMaHuyen: ", value);
    // console.log("this.filter.maHuyen: ", this.filter.maHuyen);

    this.filter.maHuyen = value;
    var response = new Array<Xa>();
    if (value !== "-1") {
      this.xaFilter.maHuyen = value;
      response = await this.xaService.filter(this.xaFilter);
    }
    this.xa = response;
  }

  async loadDropdownResources() {
    await Promise.all([
      this.doiTuongService.getAll()
        .then(res => {
          this.doiTuong = res;
          this.doiTuongOption = this.doiTuong.map(p => {
            // debugger;
            return {
              id: p.maDoiTuong.toString(),
              text: p.tenDoiTuong,
            } as Select2OptionData
          });
        }),
      this.danhXungService.getAll()
        .then(res => {
          this.danhXung = res;
          this.danhXungOption = this.danhXung.map(p => {
            return {
              id: p.maDanhXung.toString(),
              text: p.tenDanhXung,
            } as Select2OptionData;
          });
        }),
      this.chucVuService.getAll()
        .then(res => {
          this.chucVu = res;
          this.chucVuOption = this.chucVu.map(p => {
            return {
              id: p.maChucVu.toString(),
              text: p.tenChucVu,
            } as Select2OptionData;
          });
        }),
      this.loaiCanBoService.getAll()
        .then(res => {
          this.loaiCanBo = res;
          this.loaiCanBoOption = this.loaiCanBo.map(p => {
            return {
              id: p.maLoaiCanBo.toString(),
              text: p.tenLoaiCanBo,
            } as Select2OptionData;
          });
        }),
      this.donViService.getAll()
        .then(res => {
          this.donVi = res;
          this.donViOption = this.donVi.map(p => {
            return {
              id: p.maDonVi.toString(),
              text: p.tenDonVi,
            } as Select2OptionData;
          });
        }),
      this.tinhService.getAll()
        .then(res => {
          this.tinh = res as Array<Tinh>;
          this.tinhOption = this.tinh.map(p => {
            return {
              id: p.maTinh.toString(),
              text: p.tenTinh,
            } as Select2OptionData;
          });
        })
        .then(res => { }),
      this.capKhenThuongService.getAll()
        .then(res => {
          this.capKhenThuong = res;
          this.capKhenThuongOption = this.capKhenThuong.map(p => {
            return {
              id: p.maCap.toString(),
              text: p.tenCap,
            } as Select2OptionData;
          });
        }),
      this.khenThuongService.getAll()
        .then(res => {
          this.khenThuong = res;
          this.khenThuongOption = this.khenThuong.map(p => {
            return {
              id: p.maKhenThuong.toString(),
              text: p.tenKhenThuong,
            } as Select2OptionData;
          });
        }),
      this.mucGiaiThuongService.getAll()
        .then(res => {
          this.mucGiaiThuong = res;
          this.mucGiaiThuongOption = this.mucGiaiThuong.map(p => {
            return {
              id: p.maMucGiaiThuong.toString(),
              text: p.tenMucGiaiThuong,
            } as Select2OptionData;
          });
        }),
      this.hinhThucKhenThuongService.getAll()
        .then(res => {
          this.hinhThucKhenThuong = res;
          this.hinhThucKhenThuongOption = this.hinhThucKhenThuong.map(p => {
            return {
              id: p.maHinhThuc.toString(),
              text: p.tenHinhThuc,
            } as Select2OptionData;
          });
        }),
    ]);
    // .then((res) => {
    //     // let i = 0;
    //     // this.doiTuong = res[i++];
    //     // this.danhXung = res[i++];
    //     // this.chucVu = res[i++];
    //     // this.loaiCanBo = res[i++];
    //     // this.donVi = res[i++];
    //     // this.tinh = res[i++];
    //     // this.capKhenThuong = res[i++];
    //     // this.khenThuong = res[i++];
    //     // this.mucGiaiThuong = res[i++];
    //     // this.hinhThucKhenThuong = res[i++];

    //     console.log("PromiseAll.then");
    // });
    // setTimeout(() => {
    //     // jQuery('#tinhf').val('6: 10TTT').trigger('change.select2');
    //     // jQuery('#tinhf').change();
    // }, 1000);
  }

  async addDecisionToReward() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.hasBackdrop = false;
    dialogConfig.width = "900px";
    dialogConfig.maxHeight = "800px";
    const dialogRef = this.dialog.open(DecisionToRewardComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(async result => {
      if (result === true) {
        this.addDecisionToReward();
      } else {
        // this.updateTableList();
        await this.reloadData();
      }

    });
  }

  // async refreshData(flag: boolean) {
  async reloadData() {
    // if (flag === true) {
    //     // tslint:disable-next-line:max-line-length
    //     const maDoiTuong = (jQuery("#doituongF").val() == null || jQuery("#doituongF").val() === "-1") ? -1 : Number(jQuery("#doituongF").val().split(":")[1]);
    //     // tslint:disable-next-line:max-line-length
    //     const maDanhXung = (jQuery("#danhxungf").val() == null || jQuery("#danhxungf").val() === "-1") ? -1 : Number(jQuery("#danhxungf").val().split(":")[1]);
    //     // tslint:disable-next-line:max-line-length
    //     // tslint:disable-next-line:max-line-length
    //     const maChucVu = (jQuery("#chucvuf").val() == null || jQuery("#chucvuf").val() === "-1") ? -1 : Number(jQuery("#chucvuf").val().split(":")[1]);
    //     // tslint:disable-next-line:max-line-length
    //     const maLoaiCanBo = (jQuery("#loaicanbof").val() == null || jQuery("#loaicanbof").val() === "-1") ? -1 : Number(jQuery("#loaicanbof").val().split(":")[1]);
    //     // tslint:disable-next-line:max-line-length
    //     const maDonVi = (jQuery("#donvif").val() == null || jQuery("#donvif").val() === "-1") ? -1 : Number(jQuery("#donvif").val().split(":")[1]);
    //     // tslint:disable-next-line:max-line-length
    //     const maTinh: string = (jQuery("#tinhf").val() == null || jQuery("#tinhf").val() === "-1") ? -1 : jQuery("#tinhf").val().split(":")[1].trim();
    //     // tslint:disable-next-line:max-line-length
    //     const maHuyen: string = (jQuery("#huyenf").val() == null || jQuery("#huyenf").val() === "-1") ? -1 : jQuery("#huyenf").val().split(":")[1].trim();
    //     // tslint:disable-next-line:max-line-length
    //     const maXa: string = (jQuery("#xaf").val() == null || jQuery("#xaf").val() === "-1") ? -1 : jQuery("#xaf").val().split(":")[1].trim();
    //     // tslint:disable-next-line:max-line-length
    //     // const namKhen = (jQuery('#namkhenf').val() == null || jQuery('#namkhenf').val() === '-1') ? -1 : Number(jQuery('#namkhenf').val().split(':')[1]);
    //     // tslint:disable-next-line:max-line-length
    //     const maCap = (jQuery("#camkhenthuongf").val() == null || jQuery("#camkhenthuongf").val() === "-1") ? -1 : Number(jQuery("#camkhenthuongf").val().split(":")[1]);
    //     // tslint:disable-next-line:max-line-length
    //     const maHinhThuc = (jQuery("#hinhthucf").val() == null || jQuery("#hinhthucf").val() === "-1") ? -1 : Number(jQuery("#hinhthucf").val().split(":")[1]);
    //     // tslint:disable-next-line:max-line-length
    //     const maMucGiaiThuong = (jQuery("#mucgiaithuongf").val() == null || jQuery("#mucgiaithuongf").val() === "-1") ? -1 : Number(jQuery("#mucgiaithuongf").val().split(":")[1]);
    //     // tslint:disable-next-line:max-line-length
    //     const khenThuongf = (jQuery("#khenthuongF").val() == null || jQuery("#khenthuongF").val() === "-1") ? -1 : Number(jQuery("#khenthuongF").val().split(":")[1]);

    // this.filter.maDoiTuong = maDoiTuong;
    // this.filter.maDanhXung = maDanhXung;
    // this.filter.maChucVu = maChucVu;
    // this.filter.maLoaiCanBo = maLoaiCanBo;
    // this.filter.maDonVi = maDonVi;
    // this.filter.maTinh = maTinh;
    // this.filter.maHuyen = maHuyen;
    // this.filter.maXa = maXa;
    // // this.filter.namKhen = namKhen;
    // this.filter.maCap = maCap;
    // this.filter.maHinhThuc = maHinhThuc;
    // this.filter.maMucGiaiThuong = maMucGiaiThuong;
    // this.filter.khenthuong = khenThuongf;
    // }

    const data = await this.quyetDinhKhenThuongService.filter(this.filter);

    if (data == null) {
      console.log("detail data is null");
      return;
    }

    // console.log("filterData: ", data);

    this.detailData = data.rows.map(p => Object.assign(new QuyetDinhKhenThuong(), p));

    this.filter.page = data.page;

    // this.detailData.forEach((p) => {
    //     const doiTuong = this.doiTuong.find(x => x.maDoiTuong === p.maDoiTuong);
    //     const danhXung = this.danhXung.find(x => x.maDanhXung === p.maDanhXung);
    //     const chucVu = this.chucVu.find(x => x.maChucVu === p.maChucVu);
    //     const loaiCanBo = this.loaiCanBo.find(x => x.maLoaiCanBo === p.maLoaiCanBo);
    //     const donVi = this.donVi.find(x => x.maDonVi === p.maDonVi);
    //     const tinh = this.tinh.find(x => x.maTinh === p.maTinh);
    //     // const huyen = this.huyen.find(x => x.maHuyen === p.maHuyen);
    //     // const xa = this.xa.find(x => x.maXa === p.maXa);
    //     const capKhenThuong = this.capKhenThuong.find(x => x.maCap === p.maCap);
    //     const khenThuongSelected = this.khenThuong.find(x => x.maKhenThuong === p.maKhenThuong);
    //     const mucGiaiThuong = this.mucGiaiThuong.find(x => x.maMucGiaiThuong === p.maMucGiaiThuong);
    //     const hinhThucKhenThuong = this.hinhThucKhenThuong.find(x => x.maHinhThuc === p.maHinhThuc);


    //     if (doiTuong) {
    //         p.tenDoiTuong = doiTuong.tenDoiTuong;
    //     }

    //     if (danhXung) {
    //         p.tenDanhXung = danhXung.tenDanhXung;
    //     }

    //     if (chucVu) {
    //         p.tenChucVu = chucVu.tenChucVu;
    //     }

    //     if (loaiCanBo) {
    //         p.tenLoaiCanBo = loaiCanBo.tenLoaiCanBo;
    //     }

    //     if (donVi) {
    //         p.tenDonVi = donVi.tenDonVi;
    //     }

    //     if (tinh) {
    //         p.tenTinh = tinh.tenTinh;
    //     }

    //     // if (huyen) {
    //     //     p.tenHuyen = huyen.tenHuyen;
    //     // }

    //     // if (xa) {
    //     //     p.tenXa = xa.tenXa;
    //     // }

    //     if (capKhenThuong) {
    //         p.tenCap = capKhenThuong.tenCap;
    //     }

    //     if (khenThuongSelected) {
    //         p.tenKhenThuong = khenThuongSelected.tenKhenThuong;
    //     }

    //     if (mucGiaiThuong) {
    //         p.tenMucGiaiThuong = mucGiaiThuong.tenMucGiaiThuong;
    //     }

    //     if (hinhThucKhenThuong) {
    //         p.tenHinhThuc = hinhThucKhenThuong.tenHinhThuc;
    //     }
    // });

  }

  // initDataTable() {
  //     setTimeout(function () {
  //         jQuery('#zero_config').DataTable(
  //             {
  //                 'searching': false,
  //                 'lengthChange': false,
  //                 'bInfo': false,
  //                 'pageLength': 50,
  //             }
  //         );
  //     }, 1000);
  // }

  // async updateTableList() {
  //     jQuery("#zero_config").DataTable().destroy();
  //     // const seft = this;
  //     await this.refreshData();
  //     // this.initDataTable();
  // }

  editDecisionToReward(model: QuyetDinhKhenThuong) {
    debugger;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.hasBackdrop = false;
    dialogConfig.width = "900px";
    dialogConfig.maxHeight = "800px";
    dialogConfig.data = model;
    const dialogRef = this.dialog.open(DecisionToRewardComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.addDecisionToReward();
      } else {
        this.reloadData();
        // this.updateTableList();
      }
    });
  }

  viewDetail(model: QuyetDinhKhenThuong): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.hasBackdrop = false;
    dialogConfig.width = "900px";
    dialogConfig.maxHeight = "800px";
    model.readOnly = true;
    dialogConfig.data = model;
    const dialogRef = this.dialog.open(DecisionToRewardComponent, dialogConfig);
    // dialogRef.afterClosed().subscribe(result => {
    //     this.updateTableList();
    // });
  }

  async deleteDecisionToReward(model: QuyetDinhKhenThuong): Promise<void> {
    if (confirm("Bạn muốn xóa bản ghi này")) {
      const resq = await this.quyetDinhKhenThuongService.delete(model);
      this.reloadData();
      // await this.updateTableList();
    }
  }

  async onFilter() {
    this.filter.page.offset = 0;
    await this.reloadData();
  }

  async onExport() {
    let downLoadPath = AppConfigModel.ApiConfig.server + "/";
    downLoadPath += await this.quyetDinhKhenThuongService.export(this.filter);
    console.log(downLoadPath);
    const winD = window.open(downLoadPath, "_blank");
    // winD.focus();
  }

  async onExportTemplate() {
    const strNum = prompt("Nhập số dòng muốn thêm:", "0");
    if (strNum.length > 0) {
      const numberRow = Number(strNum);
      if (numberRow === NaN) {
        alert("Vui lòng nhập số.!");
        return;
      }
      let downLoadPath = AppConfigModel.ApiConfig.server + "/";
      downLoadPath += await this.quyetDinhKhenThuongService.exportTemplate(numberRow);
      const winD = window.open(downLoadPath, "_blank");
    }
  }

  onFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.filesUpload = file;
    }
  }
  async onImportTemplate() {
    const fd = new FormData();
    fd.append("fileBase", this.filesUpload);
    this.filesUpload = null;
    const res: any = await this.quyetDinhKhenThuongService.importExcel(fd);
    if (res.status === 200) {
      alert("Nhập dữ liệu thành công");
      this.reloadData();
      // await this.updateTableList();
    } else {
      alert("Nhập dữ liệu thất bại. Vui lòng kiểm tra lại file excel " + res.message);
    }
  }

  // selectDelete(item) {
  //     const index = this.listDelete.findIndex(x => x.maQd === item.maQd);
  //     if (index > -1) {
  //         this.listDelete.splice(index, 1);
  //     } else {
  //         this.listDelete.push(item);
  //     }
  // }

  async deleteList() {
    debugger;
    // this.listDelete
    if (this.detailData) {
      const listDelete = new Array<QuyetDinhKhenThuong>();
      this.detailData.forEach(p => {
        if (p.selected) {
          listDelete.push(p);
        }
      });

      if (listDelete && listDelete.length > 0 && confirm("Bạn muốn xóa danh sách bản ghi này")) {
        const resq = await this.quyetDinhKhenThuongService.deletes(listDelete);
        this.reloadData();
        // await this.updateTableList();
      }
    }
  }

  async checkAll() {
    this.selectAllRow = !this.selectAllRow;
    if (this.detailData) {
      this.detailData.forEach(p => p.selected = this.selectAllRow);
    }
  }

  async changeCheck(idx) {
    if (this.detailData[idx]) {
      this.detailData[idx].selected = !this.detailData[idx].selected;
      this.selectAllRow = this.detailData.filter(p => p.selected).length === this.detailData.length;
    }
  }

  async changeFilterPage(event: number) {
    // console.log("page: ", event);
    this.filter.page.offset = event;
    await this.reloadData();
  }

  async changeFilter(fieldName: string, event: any) {
    // console.log("changeFilter: ", event);

    // if (!event || !fieldName || !Boolean(this.filter[fieldName])) {
    // if (!fieldName || !Boolean(this.filter[fieldName])) {
    //   return;
    // }
    // debugger;
    // this.filter[fieldName] = event.value;
    this.filter[fieldName] = event;
    // await this.refreshData();
    // await this.reloadData();
  }
}
