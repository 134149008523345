import { ApiConfigModel } from './api-config.model';
import { environment } from 'environments/environment';

function _window(): any {
    // return the global native browser window object
    return window;
}

export class AppConfigModel {
    private static _ApiConfig: ApiConfigModel;
    public static get ApiConfig(): ApiConfigModel {
        if (!this._ApiConfig) {
            // this._ApiConfig = Object.assign(new ApiConfigModel(), <ApiConfigModel>environment.AppConfig);
            this._ApiConfig = Object.assign(new ApiConfigModel(), <ApiConfigModel>_window().__appConfig__);
        }
        return this._ApiConfig;
    }
}
