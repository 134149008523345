declare var jQuery: any;
import { Component, OnInit, AfterViewInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DonVi } from '../../models/don-vi.model';
import { DonViService } from '../../services/don-vi.service';
import { LoaiDonVi } from '../../models/loai-don-vi.model';
import { LoaiDonViService } from '../../services/loai-don-vi.service';
@Component({
  selector: 'app-subdivisions',
  templateUrl: './subdivisions.component.html',
  styleUrls: ['./subdivisions.component.scss']
})
export class SubdivisionsComponent implements OnInit, AfterViewInit {

  public detailData: DonVi;
  public loaiDonVi: Array<LoaiDonVi>;

  public title: string;
  public titleAdd = 'Thêm đơn vị';
  public titleUpdate = 'Cập nhật đơn vị';

  constructor(
    public services: DonViService,
    public loaidDonViService: LoaiDonViService,
    public dialogRef: MatDialogRef<SubdivisionsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DonVi
  ) {
    this.detailData = new DonVi();
    if (this.data == null) {
      this.title = this.titleAdd;
    } else {
      this.detailData = this.data;
      this.title = this.titleUpdate;
    }
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      jQuery('.select2').select2();
    }, 1000);

  }

  async ngOnInit() {
    this.loaiDonVi = await this.loaidDonViService.getAll();
  }

  handlerSave(continues: boolean, id: number) {
    if (continues) {
      this.dialogRef.close(true);
    } else {
      this.detailData.maDonVi = id;
      this.data = this.detailData;
      this.title = this.titleUpdate;
    }
  }

  // true : luu va them; false: luu
  async save(continues: boolean) {
    // save data
    // tslint:disable-next-line:max-line-length
    const maloai = (jQuery('#loaiDonVi').val() == null || jQuery('#loaiDonVi').val() === '-1') ? null : Number(jQuery('#loaiDonVi').val().split(':')[1]);

    this.detailData.maLoaiDonVi = maloai;

    if (this.data == null) {
      const resq: any = await this.services.saveData(this.detailData);
      this.handlerSave(continues, resq.data);
    } else {
      const resq: any = await this.services.updateData(this.detailData);
      this.handlerSave(continues, resq.data);
    }
  }
}
