import { Component, OnInit } from '@angular/core';
import { LoginService } from 'app/modules/admin/services/login.service';
import { UserModel } from '../../models/user/user.model';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ChangePasswordComponent } from '../../components/change-password/change-password.component';

@Component({
  selector: 'app-top-menu',
  templateUrl: './top-menu.component.html',
  styleUrls: ['./top-menu.component.scss'],
  providers: [LoginService]
})
export class TopMenuComponent implements OnInit {

  constructor(
    private _loginService: LoginService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
  }

  async onClickLogOut() {
    this._loginService.logout();
  }

  async onChangePass() {
    const user_info = JSON.parse(localStorage.getItem('user_info'));
    let userModel = new UserModel();
    userModel.id = user_info.id;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.hasBackdrop = false;
    dialogConfig.data = userModel;
    dialogConfig.width = '900px';
    const dialogRef = this.dialog.open(ChangePasswordComponent, dialogConfig);
    // dialogRef.afterClosed().subscribe(result => {
    //   location.reload();
    // });    
  }

}
