import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HinhThucKhenThuong } from '../../models/hinh-thuc-khen-thuong.model';
import { HinhThucKhenThuongService } from '../../services/hinh-thuc-khen-thuong.service';

@Component({
  selector: 'app-form-of-reward',
  templateUrl: './form-of-reward.component.html',
  styleUrls: ['./form-of-reward.component.scss']
})
export class FormOfRewardComponent implements OnInit {

  public detailData: HinhThucKhenThuong;
  public title: string;

  public titleAdd = 'Thêm hình thức khen thưởng';
  public titleUpdate = 'Cập nhật hình thức khen thưởng';

  constructor(
    public dialogRef: MatDialogRef<FormOfRewardComponent>,
    public services: HinhThucKhenThuongService,
    @Inject(MAT_DIALOG_DATA) public data: HinhThucKhenThuong
  ) {
    this.detailData = new HinhThucKhenThuong();
    if (this.data == null) {
      this.title = this.titleAdd;
    } else {
      this.detailData = this.data;
      this.title = this.titleUpdate;
    }
  }

  ngOnInit() {
  }

  handlerSave(continues: boolean, id: number) {
    if (continues) {
      this.dialogRef.close(true);
    } else {
      this.detailData.maHinhThuc = id;
      this.data = this.detailData;
      this.title = this.titleUpdate;
    }
  }

  // true : luu va them; false: luu
  async save(continues: boolean) {
    // save data
    if (this.data == null) {
      const resq: any = await this.services.saveData(this.detailData);
      this.handlerSave(continues, resq.data);
    } else {
      const resq: any = await this.services.updateData(this.detailData);
      this.handlerSave(continues, resq.data);
    }
  }

}
