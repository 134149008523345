import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ChucDanh } from '../../models/chuc-danh.model';
import { ChucDanhService } from '../../services/chuc-danh.service';
import { ChucVu } from '../../models/chuc-vu.model';

@Component({
  selector: 'app-title',
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.scss']
})
export class TitleComponent implements OnInit {

  public detailData: ChucDanh;
  public title: string;
  constructor(
    public dialogRef: MatDialogRef<TitleComponent>,
    public chucDanhService: ChucDanhService,
    @Inject(MAT_DIALOG_DATA) public data: ChucDanh
  ) {
    this.detailData = new ChucDanh();
    if (this.data == null) {
      this.title = 'Thêm chức danh';
    } else {
      this.detailData = this.data;
      this.title = 'Cập nhật chức danh';
    }
  }

  ngOnInit() {
  }
  async save(): Promise<void> {
    // save data
    const resq = await this.chucDanhService.saveData(this.detailData);
  }

}
