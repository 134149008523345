import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ChucVu } from '../../models/chuc-vu.model';
import { ChucVuService } from '../../services/chuc-vu.service';

@Component({
  selector: 'app-position',
  templateUrl: './position.component.html',
  styleUrls: ['./position.component.scss']
})
export class PositionComponent implements OnInit {

  public detailData: ChucVu;
  public title: string;

  public titleAdd = 'Thêm chức vụ';
  public titleUpdate = 'Cập nhật chức vụ';

  constructor(
    public dialogRef: MatDialogRef<PositionComponent>,
    public chucVuService: ChucVuService,
    @Inject(MAT_DIALOG_DATA) public data: ChucVu
  ) {
    this.detailData = new ChucVu();
    if (this.data == null) {
      this.title = this.titleAdd;
    } else {
      this.detailData = this.data;
      this.title = this.titleUpdate;
    }
  }

  ngOnInit() {
  }

  handlerSave(continues: boolean, id: number) {
    if (continues) {
      this.dialogRef.close(true);
    } else {
      this.detailData.maChucVu = id;
      this.data = this.detailData;
      this.title = this.titleUpdate;
    }
  }

  // true : luu va them; false: luu
  async save(continues: boolean) {
    // save data
    if (this.data == null) {
      const resq: any = await this.chucVuService.saveData(this.detailData);
      this.handlerSave(continues, resq.data);
    } else {
      const resq: any = await this.chucVuService.updateData(this.detailData);
      this.handlerSave(continues, resq.data);
    }
  }
}
