import { Injectable } from '@angular/core';
import { LoaiKhenThuong } from '../models/loai-khen-thuong.model';
import { HttpService } from './common/http.service';

@Injectable({
  providedIn: 'root'
})
export class LoaiKhenThuongService {

  constructor(
    private _httpService: HttpService,
  ) { }

  async saveData(model: LoaiKhenThuong) {
    const url = '/LoaiKhenThuong/insert';
    return this._httpService.post(url, model).toPromise();
  }

  async getAll() {
    const url = '/LoaiKhenThuong/get-all';
    const resp = await this._httpService.get(url).toPromise();
    return resp.data;
  }
}
