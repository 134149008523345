import { Component, OnInit, Inject } from '@angular/core';
import { QuanLyTaiKhoanService } from '../../services/quan-ly-tai-khoan.service';
import { UserModel } from '../../models/user/user.model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-quan-ly-tai-khoan-modal',
  templateUrl: './quan-ly-tai-khoan-modal.component.html',
  styleUrls: ['./quan-ly-tai-khoan-modal.component.scss']
})
export class QuanLyTaiKhoanModalComponent implements OnInit {

  public detailData: UserModel;
  public title: string;

  public titleAdd = 'Thêm tài khoản';
  public titleUpdate = 'Cập nhật tài khoản';

  constructor(
    public dialogRef: MatDialogRef<QuanLyTaiKhoanModalComponent>,
    public services: QuanLyTaiKhoanService,
    @Inject(MAT_DIALOG_DATA) public data: UserModel
  ) {
    this.detailData = new UserModel();
    if (this.data == null) {
      this.title = this.titleAdd;
    } else {
      this.detailData = this.data;
      this.title = this.titleUpdate;
    }
  }

  ngOnInit() {
  }

  handlerSave(continues: boolean, id: number) {
    if (continues) {
      this.dialogRef.close(true);
    } else {
      this.detailData.id = id;
      this.data = this.detailData;
      this.title = this.titleUpdate;
    }
  }

  // true : luu va them; false: luu
  async save(continues: boolean) {
    // save data
    if (this.data == null) {
      const resq: any = await this.services.saveData(this.detailData);
      this.handlerSave(continues, resq.data);
    } else {
      const resq: any = await this.services.updateData(this.detailData);
      this.handlerSave(continues, resq.data);
    }
  }

}
