declare var jQuery: any;
import { Component, OnInit, AfterViewInit, Inject } from "@angular/core";
import { ChucVu } from "../../models/chuc-vu.model";
import { ChucVuService } from "../../services/chuc-vu.service";
import { MatDialog, MatDialogConfig, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TitleComponent } from "../title/title.component";
import { PositionComponent } from "../position/position.component";
import { Criteria } from "../../models/common/criteria";

@Component({
  selector: "app-chuc-vu",
  templateUrl: "./chuc-vu.component.html",
  styleUrls: ["./chuc-vu.component.scss"]
})
export class ChucVuComponent implements OnInit, AfterViewInit {
  public detailData: Array<ChucVu>;
  public listDelete = [];
  /**
     * update
  */
  public filter: Criteria;
  public selectAllRow = false;

  ngAfterViewInit(): void {
  }
  constructor(
    public chucVuService: ChucVuService,
    public dialog: MatDialog
  ) {
    this.filter = new Criteria();
    this.detailData = new Array<ChucVu>();
  }

  async ngOnInit() {
    await this.reloadData();
  }

  async delete(model: ChucVu): Promise<void> {
    if (confirm("Bạn muốn xóa bản ghi này")) {
      const resq = await this.chucVuService.delete(model);
      await this.reloadData();
    }
  }

  add(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.hasBackdrop = false;
    dialogConfig.width = "900px";
    const dialogRef = this.dialog.open(PositionComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(async result => {
      if (result === true) {
        this.add();
      } else {
        await this.reloadData();
      }

    });
  }

  update(model: ChucVu): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.hasBackdrop = false;
    dialogConfig.width = "900px";
    dialogConfig.data = model;
    const dialogRef = this.dialog.open(PositionComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(async result => {
      if (result === true) {
        this.add();
      } else {
        await this.reloadData();
      }

    });
  }

  selectDelete(item) {
    const index = this.listDelete.findIndex(x => x.maChucVu === item.maChucVu);
    if (index > -1) {
      this.listDelete.splice(index, 1);
    } else {
      this.listDelete.push(item);
    }
  }
  async deleteList() {
    if (confirm("Bạn muốn xóa danh sách bản ghi này")) {
      const resq = await this.chucVuService.deletes(this.listDelete);
      await this.reloadData();
    }
  }

  /**
   * update
   */

  async checkAll() {
    this.selectAllRow = !this.selectAllRow;
    if (this.detailData) {
      this.detailData.forEach(p => p.selected = this.selectAllRow);
    }
  }

  async changeCheck(idx) {
    if (this.detailData[idx]) {
      this.detailData[idx].selected = !this.detailData[idx].selected;
      this.selectAllRow = this.detailData.filter(p => p.selected).length === this.detailData.length;
      debugger
    }
  }

  async changeFilterPage(event: number) {
    this.filter.page.offset = event;
    await this.reloadData();
  }

  async changeFilter(fieldName: string, event: any) {
    if (!event || !event.value || !fieldName || !Boolean(this.filter[fieldName])) {
      return;
    }
    this.filter[fieldName] = event.value;
    await this.reloadData();
  }

  async reloadData() {
    const data = await this.chucVuService.filter(this.filter);
    if (data == null) {
      return;
    }
    this.detailData = data.rows.map(p => Object.assign(new ChucVu(), p));
    this.filter.page = data.page;
  }






}
