import { HttpClient, HttpHeaders, HttpRequest, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { filter, map, take, toArray, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

import { AuthService } from './auth.service';
import { ApiResponse } from 'app/models/common/api-response.model';
import { AppConfigModel } from 'app/models/config/app-config.model';
import AuthRoutes from '../../routes/auth.routes';
import { Language } from 'app/models/enum/language.enum';

class RequestOption {
    body?: any;
    headers?: HttpHeaders | {
        [header: string]: string | string[];
    };
    observe?: any;
    params?: HttpParams | {
        [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType: any;
    withCredentials?: boolean;
}

@Injectable()
export class HttpService {


    private _apiPath = AppConfigModel.ApiConfig.url;

    constructor(
        private http: HttpClient,
        private _authService: AuthService,
        private _router: Router
    ) {
    }

    private get TOKEN(): string {
        const token = this._authService.getAccessToken();
        return token || '';
    }

    private get LANGUAGE(): string {
        // const language = localStorage['language'];
        // return language || '';
        let language = localStorage.getItem('language');
        if (!language) {
            language = Language.DEFAULT_LANGUAGE;
            localStorage.setItem('language', language);
        }
        return language;
    }

    private getDefaultRequestJsonOption() {
        const option = new RequestOption();
        option.headers = this.getDefaultHeaders();
        option.observe = 'body';
        option.responseType = 'json';
        return option;
    }

    private getDefaultHeaders() {
        let authHeaders = new HttpHeaders();
        authHeaders = authHeaders
            .set('Content-Type', 'application/json')
            .set('Data-Type', 'application/json')
            .set('Accept', 'application/json')
            .set('Accept-Language', this.LANGUAGE)
            .set('Authorization', 'bearer ' + this.TOKEN);
        return authHeaders;
    }

    public getUploadFileHeaders() {
        const authHeaders = new HttpHeaders()
            .set('Authorization', 'bearer ' + this.TOKEN)
            .set('Accept-Language', this.LANGUAGE);
        return authHeaders;
    }

    get(url: string, params?: HttpParams) {
        url = this._apiPath + url;
        const option = this.getDefaultRequestJsonOption();
        option.params = params;

        return this.executeJsonResponse('GET', url, option);
    }

    post(url: string, data: any) {
        url = this._apiPath + url;
        const option = this.getDefaultRequestJsonOption();
        option.body = data;

        return this.executeJsonResponse('POST', url, option);
    }

    private executeJsonResponse(method: string, url: string, option: RequestOption): Observable<ApiResponse> {
        return this.http.request(method, url, option)
            .pipe(
                map((res: any) => {
                    if (res.status === 500) {
                        this._router.navigate([AuthRoutes.ERROR_500_INTERNAL_ERROR]);
                    }
                    const result = <ApiResponse>Object.assign(new ApiResponse(), res);
                    return result;
                }),
                catchError((error: HttpErrorResponse) => this.handleError(error))
            );
    }

    private handleError(error: HttpErrorResponse) {
        // debugger;
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
                `Backend returned code ${error.status}, ` +
                `body was: ${error.error}`);

            let routePath = '';
            if (error.status === 404) {
                routePath = AuthRoutes.ERROR_404_NOT_FOUND;
            } else if (error.status === 401) {
                routePath = AuthRoutes.ERROR_401_UNAUTHORIZED;
            } else {
                routePath = AuthRoutes.ERROR_500_INTERNAL_ERROR;
            }
            this._router.navigate([AuthRoutes.ERROR_500_INTERNAL_ERROR]);
        }
        // return an observable with a user-facing error message
        return throwError(
            'Something bad happened; please try again later.');

        // return        Observable.of();
    }
}
