import { Injectable } from '@angular/core';
import { HttpService } from './common/http.service';
import { LinhVucHoatDong } from '../models/linh-vuc-hoat-dong.model';
import { AppConfigModel } from 'app/models/config/app-config.model';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class LinhVucHoatDongService {

  constructor(
    private _httpService: HttpService,
    private http: HttpClient
  ) { }

  async saveData (model: LinhVucHoatDong) {
    const url =  '/LinhVucHoatDong/insert';
    return this._httpService.post(url, model).toPromise();
  }

  async getAll () {
    const url =  '/LinhVucHoatDong/get-all';
    const resp = await this._httpService.get(url).toPromise();
    return resp.data;
    // return await new Promise<any>((resolve, reject) => {
    //   this.http.get(url).subscribe((response: any) => {
    //     const myObject: LinhVucHoatDong[] = response.data;
    //     resolve(myObject);
    //   });
    // });

  }
}
