<div class="row">
  <div class="col-md-12">
    <div class="card">
      <form class="form-horizontal" id="form1" name="form1" #form="ngForm">
        <div class="card-body">
          <h4 class="card-title">{{title}}</h4>

          <!-- ten -->
          <div class="form-group row">
              <label for="doituongD" class="col-sm-2 text-right control-label col-form-label">Đối tượng</label>
              <div class="col-sm-2">
                <select id="doituongD" name="doituong" [(ngModel)]="detailData.maDoiTuong" class="select2 form-control custom-select"
                  style="width: 100%; height:36px;">
                  <option value="">Chọn đối tượng</option>
                  <option *ngFor="let c of doiTuong" [ngValue]="c.maDoiTuong">{{c.tenDoiTuong}}</option>
                </select>
              </div>


              <label for="danhxungD" class="text-right control-label col-form-label">Danh xưng</label>
              <div class="col-sm-2">
                <select id="danhxungD" name="danhXung" [(ngModel)]="detailData.maDanhXung" class="select2 form-control custom-select"
                  style="width: 100%; height:36px;">
                  <option value="">Chọn danh xưng</option>
                  <option *ngFor="let c of danhXung" [ngValue]="c.maDanhXung">{{c.tenDanhXung}}</option>
                </select>
              </div>

            <label for="lname" class=" text-right control-label col-form-label">Tập thể/ Cá nhân</label>
            <div class="col-sm-3">
              <input type="text" class="form-control" name="ten" [(ngModel)]="detailData.ten" placeholder="Nhập Họ và tên">
            </div>
          </div>
          <!-- dia chi -->
          <div class="form-group row">
            <label for="diaChi" class="col-sm-2 text-right control-label col-form-label">Địa chỉ</label>
            <div class="col-sm-10">
              <input type="text" class="form-control" [(ngModel)]="detailData.diaChi" placeholder="Nhập Địa chỉ" name="diachi">
            </div>
          </div>
        
          <!-- loai cai bo -->
          <div class="form-group row">


              <label for="chucvuD" class="col-sm-2 text-right control-label col-form-label">Chức vụ</label>
              <div class="col-sm-4">
                <select id="chucvuD" name="chucvuD" [(ngModel)]="detailData.maChucVu" class="select2 form-control custom-select" style="width: 100%; height:36px;">
                    <option value="">Chọn chức vụ</option>
                  <option *ngFor="let c of chucVu" [ngValue]="c.maChucVu">{{c.tenChucVu}}</option>
                </select>
              </div>

            <label for="loaicanboD" class="col-sm-2 text-right control-label col-form-label">Loại cán bộ</label>
            <div class="col-sm-4">
              <select id="loaicanboD" name="loaicanboD" [(ngModel)]="detailData.maLoaiCanBo" class="select2 form-control custom-select"
                style="width: 100%; height:36px;">
                <option value="">Chọn loại cán bộ</option>
                <option *ngFor="let c of loaiCanBo" [ngValue]="c.maLoaiCanBo">{{c.tenLoaiCanBo}}</option>
              </select>
            </div>
          </div>
          <!-- don vi -->
          <div class="form-group row">
            <label for="donviD" class="col-sm-2 text-right control-label col-form-label">Đơn vị</label>
            <div class="col-sm-10">
              <select id="donviD" name="donviD" [(ngModel)]="detailData.maDonVi" class="select2 form-control custom-select" style="width: 100%; height:36px;">
                  <option value="">Chọn đơn vị</option>
                <option *ngFor="let c of donVi" [ngValue]="c.maDonVi">{{c.tenDonVi}}</option>
              </select>
            </div>
          </div>
          <!-- tinh -->
          <div class="form-group row">
            <label for="fname" class="col-sm-2 text-right control-label col-form-label">Tỉnh</label>
            <div class="col-sm-3">
              <select id="tinhDetail" name="tinh" [(ngModel)]="detailData.maTinh" class="select2 form-control custom-select" style="width: 100%; height:36px;">
                  <option value=":">Chọn tinh</option>
                <option *ngFor="let c of tinh" [ngValue]="c.maTinh">{{c.tenTinh}}</option>
              </select>
            </div>

            <label for="fname" class="text-right control-label col-form-label">Huyện</label>
            <div class="col-sm-3">
              <select id="huyenDetail" name="huyen" [(ngModel)]="detailData.maHuyen" class="select2 form-control custom-select" style="width: 100%; height:36px;">
                  <option value=":">Chọn huyện</option>
                <option *ngFor="let c of huyen" [ngValue]="c.maHuyen">{{c.tenHuyen}}</option>
              </select>
            </div>

            <label for="fname" class="text-right control-label col-form-label">Xã</label>
            <div class="col-sm-3">                
              <select id="xaDetail" name="xa" [(ngModel)]="detailData.maXa" class="select2 form-control custom-select" style="width: 100%; height:36px;">
                  <option value=":">Chọn xã</option>
                <option *ngFor="let c of xa" [ngValue]="c.maXa">{{c.tenXa}}</option>
              </select>
            </div>


          </div>
          
          <!-- ngay quyet dinh -->
          <div class="form-group row">
              <label for="laddress" class="col-sm-2 text-right control-label col-form-label">Số quyết định</label>
              <div class="col-sm-4">
                <input type="text" class="form-control" id="soQuyetDinh" [(ngModel)]="detailData.soQuyetDinh" placeholder="Nhập Số quyết định"
                  name="soQuyetDinh">
              </div>

            <label for="laddress" class="col-sm-2 text-right control-label col-form-label">Ngày quyết định</label>
            <div class="col-sm-4">
              <input type="date" class="form-control " id="laddress" [ngModel]="detailData.ngayQuyetDinh | date:'yyyy-MM-dd'"
                (ngModelChange)="detailData.ngayQuyetDinh = $event" placeholder="Chọn Ngày quyết định" name="ngayquyetdinh">
            </div>
          </div>

          <!--so dinh danh -->
          <div class="form-group row">
            <label for="soDinhDanh" class="col-sm-2 text-right control-label col-form-label">Số định danh</label>
            <div class="col-sm-4">
              <input type="text" class="form-control" id="soDinhDanh" [(ngModel)]="detailData.soDinhDanh" placeholder="Nhập Số định danh"
                name="soDinhDanh">
            </div>

          <label for="laddress" class="col-sm-2 text-right control-label col-form-label">Tháng năm sinh</label>
          <div class="col-sm-4">
            <input type="date" class="form-control " id="namSinh" [ngModel]="detailData.ngaySinh | date:'yyyy-MM-dd'"
              (ngModelChange)="detailData.ngaySinh = $event" name="namSinh">
          </div>
        </div>

          <!-- nam khen -->
          <div class="form-group row">
            <label for="laddress" class="col-sm-2 text-right control-label col-form-label">Năm khen thưởng</label>
            <div class="col-sm-3">
              <input type="number" class="form-control" id="laddress" [(ngModel)]="detailData.namKhen" placeholder="Nhập Năm khen"
                name="namkhen">
            </div>

            <label class="text-right control-label col-form-label">Đính kèm file</label>
            <div class="col-md-4">
                <div class="custom-file">
                    <input name="fileupload" type="file" class="custom-file-input" id="validatedCustomFile" (change)="onFileChange($event)" accept="application/pdf">
                    <label class="custom-file-label" for="validatedCustomFile">Chọn tập tin...</label>                    
                </div>
            </div>
            <a class="text-right control-label col-form-label" *ngIf="data !=null && detailData.attachment" href="{{pathDownLoad}}" target="_blank">Tải xuống</a>
          </div>
          <!-- cap khen thuong -->
          <div class="form-group row">
            <label for="capkhenthuongD" class="col-sm-2 text-right control-label col-form-label">Cấp khen thưởng</label>
            <div class="col-sm-3">
              <select id="capkhenthuongD" name="capkhenthuongD" [(ngModel)]="detailData.maCap" class="select2 form-control custom-select"
                style="width: 100%; height:36px;">
                <option value="">Chọn cấp khen thưởng</option>
                <option *ngFor="let c of capKhenThuong" [ngValue]="c.maCap">{{c.tenCap}}</option>
              </select>
            </div>

            <label for="hinhthuckhenthuongD" class="text-right control-label col-form-label">Hình thức khen thưởng</label>
            <div class="col-sm-4">
              <select id="hinhthuckhenthuongD" name="hinhthuckhenthuongD" [(ngModel)]="detailData.maHinhThuc" class="select2 form-control custom-select"
                style="width: 100%; height:36px;">
                <option value="">Chọn hình thức khen thưởng</option>
                <option *ngFor="let c of hinhThucKhenThuong" [ngValue]="c.maHinhThuc">{{c.tenHinhThuc}}</option>
              </select>
            </div>
          </div>

           <!-- loai khen thuong -->
           <div class="form-group row">
              <label for="loaikhenthuongD" class="col-sm-2 text-right control-label col-form-label">Loại khen thưởng</label>
              <div class="col-sm-4">
                <select id="loaikhenthuongD" name="loaikhenthuongD" class="form-control custom-select"
                  style="width: 100%; height:36px;" (change)="onChangeLoaiKhenThuong($event.target.value)" >
                  <option *ngFor="let c of loaiKhenThuong" [value]="c.maLoaiKhenThuong">{{c.tenLoaiKhenThuong}}</option>
                </select>
              </div>

              <label for="khenthuongD" class="text-right control-label col-form-label">Danh hiệu</label>
            <div class="col-sm-5">
              <select id="khenthuongD" name="khenthuongD" [(ngModel)]="detailData.maKhenThuong" class="select2 form-control custom-select"
                style="width: 100%; height:36px;">
                <option value="">Chọn danh hiệu</option>
                <option *ngFor="let c of khenThuong" [ngValue]="c.maKhenThuong">{{c.tenKhenThuong}}</option>
              </select>
            </div>
            </div>

          <!-- muc giai thuong -->
          <div class="form-group row" *ngIf="isShow"> 
            <label for="mucgiaithuongD" class="col-sm-2 text-right control-label col-form-label">Mục giải thưởng</label>
            <div class="col-sm-10">
              <select id="mucgiaithuongD" name="mucgiaithuongD" [(ngModel)]="detailData.maMucGiaiThuong" class="select2 form-control custom-select"
                style="width: 100%; height:36px;">
                <option value="">Chọn mục giải thưởng</option>
                <option *ngFor="let c of mucGiaiThuong" [ngValue]="c.maMucGiaiThuong">{{c.tenMucGiaiThuong}}</option>
              </select>
            </div>
          </div>
          <!-- ghi chu -->
          <div class="form-group row">
            <label for="laddress" class="col-sm-2 text-right control-label col-form-label">Ghi chú</label>
            <div class="col-sm-10">
              <input type="text" class="form-control" id="laddress" [(ngModel)]="detailData.ghiChu" placeholder="Nhập Ghi chú"
                name="ghichu">
            </div>
          </div>



        </div>
        <div class="border-top">
          <div class="card-body float-right">
              <button *ngIf="!detailData.readOnly" type="button" class="btn btn-primary" (click)="save(true)">Lưu và thêm</button>
              &nbsp;
            <button *ngIf="!detailData.readOnly" type="button" class="btn btn-primary" (click)="save(false)">Lưu</button>
            &nbsp;
            <button type="button" class="btn btn-secondary" mat-dialog-close>Hủy</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>