import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ControlContainer, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { EmulationAndRewardComponent } from './components/emulation-and-reward/emulation-and-reward.component';
import { EmulationAndRewardDetailComponent } from './components/emulation-and-reward-detail/emulation-and-reward-detail.component';
import { ObjectsComponent } from './components/objects/objects.component';
import { PositionComponent } from './components/position/position.component';
import { TitleComponent } from './components/title/title.component';
import { FormOfRewardComponent } from './components/form-of-reward/form-of-reward.component';
import { FieldOfActivityComponent } from './components/field-of-activity/field-of-activity.component';
import { UnitTypeComponent } from './components/unit-type/unit-type.component';
import { RewardModeComponent } from './components/reward-mode/reward-mode.component';
import { NatureGiftsComponent } from './components/nature-gifts/nature-gifts.component';
import { DecisionToRewardComponent } from './components/decision-to-reward/decision-to-reward.component';
import { SubdivisionsComponent } from './components/subdivisions/subdivisions.component';
import { TopMenuComponent } from 'app/modules/admin/layouts/top-menu/top-menu.component';
import { LeftMenuComponent } from 'app/modules/admin/layouts/left-menu/left-menu.component';
import { AdminFooterComponent } from 'app/modules/admin/layouts/admin-footer/admin-footer.component';
import { ChucVuComponent } from './components/chuc-vu/chuc-vu.component';
import { CapKhenThuongComponent } from './components/cap-khen-thuong/cap-khen-thuong.component';
import { DonViComponent } from './components/don-vi/don-vi.component';
import { HinhThucKhenThuongComponent } from './components/hinh-thuc-khen-thuong/hinh-thuc-khen-thuong.component';
import { KhenThuongComponent } from './components/khen-thuong/khen-thuong.component';
import { DoiTuongComponent } from './components/doi-tuong/doi-tuong.component';
import { DanhXungComponent } from './components/danh-xung/danh-xung.component';
import { QuanLyTaiKhoanComponent } from './components/quan-ly-tai-khoan/quan-ly-tai-khoan.component';
import { UserLoginGuard } from './guard/user-login.guard';
import { BaoCaoComponent } from "app/modules/admin/components/bao-cao/bao-cao.component";

const routes: Routes = [{
    path: 'login',
    component: LoginComponent
}, {
    path: '',
    component: AdminLayoutComponent,
    // canActivate: [MustLoginGuard],
    children: [
        { path: '', component: EmulationAndRewardComponent, canActivate: [UserLoginGuard]},
        // { path: 'dashboard', component: DashboardComponent },
        // { path: 'emulationAndReward', component: EmulationAndRewardComponent },
        { path: 'emulation-reward', component: EmulationAndRewardComponent, canActivate: [UserLoginGuard] },
        { path: 'emulation-and-reward-detail', component: EmulationAndRewardDetailComponent , canActivate: [UserLoginGuard]},
        { path: 'chuc-vu', component: ChucVuComponent , canActivate: [UserLoginGuard]},
        { path: 'cap-khen-thuong', component: CapKhenThuongComponent , canActivate: [UserLoginGuard]},
        { path: 'don-vi', component: DonViComponent, canActivate: [UserLoginGuard] },
        { path: 'hinh-thuc-khen-thuong', component: HinhThucKhenThuongComponent , canActivate: [UserLoginGuard]},
        { path: 'khen-thuong', component: KhenThuongComponent, canActivate: [UserLoginGuard] },
        { path: 'doi-tuong', component: DoiTuongComponent, canActivate: [UserLoginGuard] },
        { path: 'danh-xung', component: DanhXungComponent , canActivate: [UserLoginGuard]},
        { path: 'quan-ly-tai-khoan', component: QuanLyTaiKhoanComponent, canActivate: [UserLoginGuard] },
        { path: 'bao-cao', component: BaoCaoComponent, canActivate: [UserLoginGuard] },
        { path: 'login', component: LoginComponent },
    ],
},
];

export const AdminModuleImports = [
    CommonModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
];

export const AdminModuleComponents = [
    DashboardComponent,
    LeftMenuComponent,
    TopMenuComponent,
    LoginComponent,
    AdminLayoutComponent,
    EmulationAndRewardComponent,
    EmulationAndRewardDetailComponent,
    SubdivisionsComponent,
    ObjectsComponent,
    PositionComponent,
    TitleComponent,
    FormOfRewardComponent,
    FieldOfActivityComponent,
    UnitTypeComponent,
    RewardModeComponent,
    NatureGiftsComponent,
    DecisionToRewardComponent,
    AdminFooterComponent,
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AdminRoutingModule { }
