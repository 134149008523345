import { Injectable } from '@angular/core';
import { HttpService } from './common/http.service';
import { PhuongThucKhenThuong } from '../models/phuong-thuc-khen-thuong.model';
import { AppConfigModel } from 'app/models/config/app-config.model';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PhuongThucKhenThuongService {

  constructor(
    private _httpService: HttpService,
    private http: HttpClient
  ) { }

  async saveData(model: PhuongThucKhenThuong) {
    const url = '/PhuongThucKhenThuong/insert';
    return this._httpService.post(url, model).toPromise();
  }

  async getAll() {
    const url = '/PhuongThucKhenThuong/get-all';
    const resp = await this._httpService.get(url).toPromise();
    return resp.data;

    // return await new Promise<any>((resolve, reject) => {
    //   this.http.get(url).subscribe((response: any) => {
    //     const myObject: PhuongThucKhenThuong[] = response.data;
    //     resolve(myObject);
    //   });
    // });

  }
}
