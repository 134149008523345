declare var jQuery: any;
import { Component, OnInit, Inject, AfterViewInit } from '@angular/core';
import { KhenThuongService } from '../../services/khen-thuong.service';
import { KhenThuong } from '../../models/khen-thuong.model';
import { LoaiKhenThuongService } from '../../services/loai-khen-thuong.service';
import { LoaiKhenThuong } from '../../models/loai-khen-thuong.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { KhenThuongComponent } from '../khen-thuong/khen-thuong.component';

@Component({
  selector: 'app-khen-thuong-modal',
  templateUrl: './khen-thuong-modal.component.html',
  styleUrls: ['./khen-thuong-modal.component.scss']
})
export class KhenThuongModalComponent implements OnInit, AfterViewInit {

  public detailData: KhenThuong;
  public loaiKhenThuong: Array<LoaiKhenThuong>;

  public title: string;
  public titleAdd = 'Thêm khen thưởng';
  public titleUpdate = 'Cập nhật khen thưởng';

  constructor(
    public services: KhenThuongService,
    public loaiKhenThuongService: LoaiKhenThuongService,
    public dialogRef: MatDialogRef<KhenThuongComponent>,
    @Inject(MAT_DIALOG_DATA) public data: KhenThuong
  ) {
    this.detailData = new KhenThuong();
    if (this.data == null) {
      this.title = this.titleAdd;
    } else {
      this.detailData = this.data;
      this.title = this.titleUpdate;
    }
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      jQuery('.select2').select2();
    }, 1000);

  }

  async ngOnInit() {
    this.loaiKhenThuong = await this.loaiKhenThuongService.getAll();
  }

  handlerSave(continues: boolean, id: number) {
    if (continues) {
      this.dialogRef.close(true);
    } else {
      this.detailData.maKhenThuong = id;
      this.data = this.detailData;
      this.title = this.titleUpdate;
    }
  }

  // true : luu va them; false: luu
  async save(continues: boolean) {
    // save data
    // tslint:disable-next-line:max-line-length
    const maloai = (jQuery('#loaiKhenThuong').val() == null || jQuery('#loaiKhenThuong').val() === '-1') ? null : Number(jQuery('#loaiKhenThuong').val().split(':')[1]);

    this.detailData.maLoaiKhenThuong = maloai;

    if (this.data == null) {
      const resq: any = await this.services.saveData(this.detailData);
      this.handlerSave(continues, resq.data);
    } else {
      const resq: any = await this.services.updateData(this.detailData);
      this.handlerSave(continues, resq.data);
    }
  }

}
