import { StatusCode } from '../enum/status-code.model';

export class ApiResponse {
    public data: any;
    public message: string;
    public status: StatusCode;
    // public static get isSuccess(this: TangoResponse) { return this.statusCode == StatusCode.Success; }
    public isSuccess(): boolean { return this.status === StatusCode.Success; }
}

