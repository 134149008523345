<div class="page-wrapper">
    <div class="container-fluid">
        <!-- ============================================================== -->
        <!-- Start Page Content -->
        <!-- ============================================================== -->
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title">

                            <button type="button" class="btn btn-primary float-right" (click)="add()"><i
                                    class="fas fa-id-card"></i>
                                Thêm mới</button>
                                <button (click)="deleteList()" type="button" class="btn btn-danger float-right"><i
                                    class="fas fa-check-square"></i>
                                Xóa</button>

                        </h5>
                        <br>
                        <br>
                        <div class="row">
                            <div class="col-md-3 offset-md-9">
                                <div class="form-group row">
                                    <label class="col-md-3 col-form-label">Tìm kiếm:</label>
                                    <div class="col-md-9">
                                        <input (keyup.enter)="reloadData()" type="text" class="form-control"
                                            name="lname" [(ngModel)]="filter.searchText">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="table-responsive">
                            <table id="donViTbl" class="table table-striped table-bordered">
                                <thead>
                                    <tr>
                                        <th class="align-middle">
                                            <input type="checkbox" [checked]="selectAllRow" (change)="checkAll()" />
                                        </th>
                                        <th class="align-middle">STT</th>
                                        <th class="align-middle">Loại đơn vị</th>
                                        <th class="align-middle">Tên cấp khen thưởng</th>
                                        <th class="align-middle">#</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let c of detailData; index as i">
                                        <td><input type="checkbox" (change)="selectDelete(c)"></td>
                                        <td>{{i+1 + filter.page.offset}}</td>
                                        <td>{{c.tenLoaiDonVi}}</td>
                                        <td>{{c.tenDonVi}}</td>

                                        <td>
                                            <a routerLinkActive="active" (click)="update(c)" href="javascript:;"><i
                                                    class="fas fa-edit"></i></a>

                                            <a routerLinkActive="active" (click)="delete(c)" href="javascript:;"><i
                                                    class="fas fa-eraser"></i></a>
                                        </td>
                                    </tr>
                                </tbody>

                            </table>
                        </div>
                        <app-paging-count [page]="filter.page"></app-paging-count>
                        <app-paging-list [page]="filter.page" (change)="changeFilterPage($event)"></app-paging-list>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>