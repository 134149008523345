import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { AdminRoutingModule, AdminModuleComponents } from "./admin-routing.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { MatDialogModule } from "@angular/material/dialog";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { DecisionToRewardComponent } from "./components/decision-to-reward/decision-to-reward.component";
import { NatureGiftsComponent } from "./components/nature-gifts/nature-gifts.component";
import { UnitTypeComponent } from "./components/unit-type/unit-type.component";
import { RewardModeComponent } from "./components/reward-mode/reward-mode.component";
import { FieldOfActivityComponent } from "./components/field-of-activity/field-of-activity.component";
import { FormOfRewardComponent } from "./components/form-of-reward/form-of-reward.component";
import { PositionComponent } from "./components/position/position.component";
import { ObjectsComponent } from "./components/objects/objects.component";
import { SubdivisionsComponent } from "./components/subdivisions/subdivisions.component";
import { TitleComponent } from "./components/title/title.component";
import { LoginComponent } from "./components/login/login.component";
import { ChucVuComponent } from "./components/chuc-vu/chuc-vu.component";
import { CapKhenThuongComponent } from "./components/cap-khen-thuong/cap-khen-thuong.component";
import { CapKhenThuongModalComponent } from "./components/cap-khen-thuong-modal/cap-khen-thuong-modal.component";
import { DonViComponent } from "./components/don-vi/don-vi.component";
import { HinhThucKhenThuongComponent } from "./components/hinh-thuc-khen-thuong/hinh-thuc-khen-thuong.component";
import { KhenThuongComponent } from "./components/khen-thuong/khen-thuong.component";
import { KhenThuongModalComponent } from "./components/khen-thuong-modal/khen-thuong-modal.component";
import { DoiTuongComponent } from "./components/doi-tuong/doi-tuong.component";
import { DanhXungComponent } from "./components/danh-xung/danh-xung.component";
import { DanhXungModalComponent } from "./components/danh-xung-modal/danh-xung-modal.component";
import { QuanLyTaiKhoanComponent } from "./components/quan-ly-tai-khoan/quan-ly-tai-khoan.component";
import { QuanLyTaiKhoanModalComponent } from "./components/quan-ly-tai-khoan-modal/quan-ly-tai-khoan-modal.component";
import { UserLoginGuard } from "./guard/user-login.guard";
import { ChangePasswordComponent } from "./components/change-password/change-password.component";
import { NgSelect2Module  } from "ng-select2";
import { PagingListComponent } from "./components/paging/paging-list/paging-list.component";
import { PagingCountComponent } from "./components/paging/paging-count/paging-count.component";
import { PagingOffsetComponent } from "./components/paging/paging-offset/paging-offset.component";
import { BaoCaoComponent } from './components/bao-cao/bao-cao.component';

@NgModule({
  declarations: [
    AdminModuleComponents,
    LoginComponent,
    ChucVuComponent,
    CapKhenThuongComponent,
    CapKhenThuongModalComponent,
    DonViComponent,
    HinhThucKhenThuongComponent,
    KhenThuongComponent,
    KhenThuongModalComponent,
    DoiTuongComponent,
    DanhXungComponent,
    DanhXungModalComponent,
    QuanLyTaiKhoanComponent,
    QuanLyTaiKhoanModalComponent,
    ChangePasswordComponent,
    PagingListComponent,
    PagingCountComponent,
    PagingOffsetComponent,
    BaoCaoComponent,
  ],
  imports: [
    CommonModule,
    AdminRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    // HttpModule,
    HttpClientModule,
    MatDialogModule,
    BrowserAnimationsModule,
    NgSelect2Module ,
    //  RouterModule
  ],
  providers: [
    HttpClientModule,
    // HttpModule,
    UserLoginGuard
  ],
  entryComponents: [
    SubdivisionsComponent,
    ObjectsComponent,
    PositionComponent,
    TitleComponent,
    FormOfRewardComponent,
    FieldOfActivityComponent,
    UnitTypeComponent,
    RewardModeComponent,
    NatureGiftsComponent,
    DecisionToRewardComponent,
    CapKhenThuongModalComponent,
    KhenThuongModalComponent,
    DanhXungModalComponent,
    QuanLyTaiKhoanModalComponent,
    ChangePasswordComponent
  ]
})
export class AdminModule { }
