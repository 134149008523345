<div class="page-wrapper">
  <div class="container-fluid">
    <!-- ============================================================== -->
    <!-- Start Page Content -->
    <!-- ============================================================== -->
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-4 report-text">
                <span>Báo cáo so sánh số lượng Danh hiệu thi đua từng cơ quan qua các năm</span>
              </div>
              <div class="col-6">
                <button type="button" class="btn btn-success" (click)="onClickReportByCoQuan()">
                  <i class="fas fa-download"></i> Xuất file
                </button>
              </div>
            </div>

            <br/>

            <div class="row">
              <div class="col-4 report-text">
                <span>Báo cáo so sánh số lượng Danh hiệu thi đua từng doanh nghiệp qua các năm</span>
              </div>
              <div class="col-6">
                <button type="button" class="btn btn-success"  (click)="onClickReportByDoanhNghiep()" >
                  <i class="fas fa-download"></i> Xuất file
                </button>
              </div>
            </div>

            <br/>

            <div class="row">
              <div class="col-4 report-text">
                <span>Báo cáo so sánh số lượng Danh hiệu thi đua từng tổ chức qua các năm</span>
              </div>
              <div class="col-6">
                <button type="button" class="btn btn-success" (click)="onClickReportByToChucKhac()" >
                  <i class="fas fa-download"></i> Xuất file
                </button>
              </div>
            </div>

            <br/>

            <div class="row">
              <div class="col-4 report-text">
                <span>Báo cáo so sánh số lượng Danh hiệu thi đua toàn tỉnh theo huyện qua các năm</span>
              </div>
              <div class="col-6">
                <button type="button" class="btn btn-success" (click)="onClickReportByHuyen()" >
                  <i class="fas fa-download"></i> Xuất file
                </button>
              </div>
            </div>

            <br/>

            <div class="row">
              <div class="col-4 report-text">
                <span>Báo cáo so sánh số lượng từng Danh hiệu thi đua qua các năm</span>
              </div>
              <div class="col-6">
                <button type="button" class="btn btn-success" (click)="onClickReportByDanhHieu()" >
                  <i class="fas fa-download"></i> Xuất file
                </button>
              </div>
            </div>

            <br/>

            <div class="row">
              <div class="col-4 report-text">
                <span>Báo cáo so sánh số lượng từng Hình thức khen thưởng qua các năm</span>
              </div>
              <div class="col-6">
                <button type="button" class="btn btn-success"  (click)="onClickReportByHinhThuc()" >
                  <i class="fas fa-download"></i> Xuất file
                </button>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>
