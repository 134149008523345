<div class="row">
  <div class="col-md-12">
      <div class="card">
          <form class="form-horizontal">
              <div class="card-body">
                  <h4 class="card-title">{{title}}</h4>                    
                  <div class="form-group row">
                      <label for="userName" class="col-sm-3 text-right control-label col-form-label">Tên đăng nhập</label>
                      <div class="col-sm-9">
                          <input type="text" class="form-control" name="userName" [(ngModel)]="detailData.userName" placeholder="Nhập Tên đăng nhập">
                      </div>
                  </div>

                  <div class="form-group row">
                    <label for="fullName" class="col-sm-3 text-right control-label col-form-label">Họ và tên</label>
                    <div class="col-sm-9">
                        <input type="text" class="form-control" name="fullName" [(ngModel)]="detailData.fullName" placeholder="Nhập Họ và tên">
                    </div>
                </div>

                <div class="form-group row">
                  <label for="password" class="col-sm-3 text-right control-label col-form-label">Mật khẩu</label>
                  <div class="col-sm-9">
                      <input type="password" class="form-control" name="password" [(ngModel)]="detailData.password" placeholder="Nhập Mật khẩu">
                  </div>
              </div>

                <div class="form-group row">
                  <label for="email" class="col-sm-3 text-right control-label col-form-label">Email</label>
                  <div class="col-sm-9">
                      <input type="text" class="form-control" name="email" [(ngModel)]="detailData.email" placeholder="Nhập Email">
                  </div>
              </div>

              <div class="form-group row">
                <label for="address" class="col-sm-3 text-right control-label col-form-label">Địa chỉ</label>
                <div class="col-sm-9">
                    <input type="text" class="form-control" name="address" [(ngModel)]="detailData.address" placeholder="Nhập Địa chỉ">
                </div>
            </div>

            <div class="form-group row">
              <label for="chucvuD" class="col-sm-3 text-right control-label col-form-label">Quyền</label>
              <div class="col-sm-9">
                <select id="chucvuD" name="chucvuD" [(ngModel)]="detailData.roleId" class="form-control custom-select" style="width: 100%; height:36px;">
                    <option value="1">Quản trị viên</option>
                    <option value="2">Thành viên</option>
                </select>
              </div>
          </div>


              </div>
              <div class="border-top">
                  <div class="card-body float-right">
                          <button type="button" class="btn btn-primary" (click)="save(true)">Lưu và thêm</button>
                          &nbsp;
                        <button type="button" class="btn btn-primary" (click)="save(false)">Lưu</button>
                        &nbsp;
                        <button type="button" class="btn btn-secondary" mat-dialog-close>Hủy</button>
                  </div>
              </div>
          </form>
      </div>
  </div>          
</div>