
<div class="main-wrapper">
  <!-- ============================================================== -->
  <!-- Preloader - style you can find in spinners.css -->
  <!-- ============================================================== -->
  <!-- <div class="preloader">
      <div class="lds-ripple">
          <div class="lds-pos"></div>
          <div class="lds-pos"></div>
      </div>
  </div> -->
  <!-- ============================================================== -->
  <!-- Preloader - style you can find in spinners.css -->
  <!-- ============================================================== -->
  <!-- ============================================================== -->
  <!-- Login box.scss -->
  <!-- ============================================================== -->
  <div class="auth-wrapper d-flex no-block justify-content-center align-items-center bg-dark">
      <div class="auth-box bg-dark">
          <div id="loginform">
              <div class="text-center p-t-20 p-b-20">
                  <span class="db"><img src="../../assets/images/logo-icon.png" alt="logo" /></span>
                  <span class="db"><img width="100%" style="padding-bottom: 9px" src="../../assets/images/tenpm.PNG" alt="logo" /></span>
              </div>
              <!-- Form -->
              <form class="form-horizontal m-t-20" id="loginform" [formGroup]="loginForm" (ngSubmit)="onLoginSubmit()">
                  <div class="row p-b-30">
                      <div class="col-12">
                          <div class="input-group mb-3">
                              <div class="input-group-prepend">
                                  <span class="input-group-text bg-success text-white" id="basic-addon1"><i class="ti-user"></i></span>
                              </div>
                              <input type="text" formControlName="username" class="form-control form-control-lg" placeholder="Tên đăng nhập" aria-label="Username" aria-describedby="basic-addon1" required="">
                          </div>
                          <div class="input-group mb-3">
                              <div class="input-group-prepend">
                                  <span class="input-group-text bg-warning text-white" id="basic-addon2"><i class="ti-pencil"></i></span>
                              </div>
                              <input type="password" formControlName="password" class="form-control form-control-lg" placeholder="Mật khẩu" aria-label="Password" aria-describedby="basic-addon1" required="">
                          </div>
                          <div *ngIf="error" class="alert alert-danger">{{error}}</div>
                      </div>
                  </div>
                  <div class="row">
                      <div class="col-12">
                          <div class="form-group">
                              <div class="p-t-20">                               
                                  <button class="btn btn-success float-right" type="submit">Đăng nhập</button>
                              </div>
                          </div>
                      </div>
                  </div>
              </form>
          </div>
          <div id="recoverform">
              <div class="text-center">
                  <span class="text-white">Enter your e-mail address below and we will send you instructions how to recover a password.</span>
              </div>
              <div class="row m-t-20">
                  <!-- Form -->
                  <form class="col-12" action="index.html">
                      <!-- email -->
                      <div class="input-group mb-3">
                          <div class="input-group-prepend">
                              <span class="input-group-text bg-danger text-white" id="basic-addon1"><i class="ti-email"></i></span>
                          </div>
                          <input type="text" class="form-control form-control-lg" placeholder="Email Address" aria-label="Username" aria-describedby="basic-addon1">
                      </div>
                      <!-- pwd -->
                      <div class="row m-t-20 p-t-20 border-top border-secondary">
                          <div class="col-12">
                              <a class="btn btn-success" href="#" id="to-login" name="action">Back To Login</a>
                              <button class="btn btn-info float-right" type="button" name="action">Recover</button>
                          </div>
                      </div>
                  </form>
              </div>
          </div>
      </div>
  </div>
  <!-- ============================================================== -->
  <!-- Login box.scss -->
  <!-- ============================================================== -->
  <!-- ============================================================== -->
  <!-- Page wrapper scss in scafholding.scss -->
  <!-- ============================================================== -->
  <!-- ============================================================== -->
  <!-- Page wrapper scss in scafholding.scss -->
  <!-- ============================================================== -->
  <!-- ============================================================== -->
  <!-- Right Sidebar -->
  <!-- ============================================================== -->
  <!-- ============================================================== -->
  <!-- Right Sidebar -->
  <!-- ============================================================== -->
</div>
<!-- ============================================================== -->
<!-- All Required js -->
<!-- ============================================================== -->
<script src="../../assets/libs/jquery/dist/jquery.min.js"></script>
<!-- Bootstrap tether Core JavaScript -->
<!-- <script src="../../assets/libs/popper.js/dist/umd/popper.min.js"></script> -->
<script src="../../assets/libs/bootstrap/dist/js/bootstrap.min.js"></script>
<!-- ============================================================== -->
<!-- This page plugin js -->
<!-- ============================================================== -->
<!-- <script>

$('[data-toggle="tooltip"]').tooltip();
$(".preloader").fadeOut();
// ============================================================== 
// Login and Recover Password 
// ============================================================== 
$('#to-recover').on("click", function() {
  $("#loginform").slideUp();
  $("#recoverform").fadeIn();
});
$('#to-login').click(function(){
  
  $("#recoverform").hide();
  $("#loginform").fadeIn();
});
</script> -->
