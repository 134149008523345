import { Injectable } from '@angular/core';
import { Xa } from '../models/Xa/xa.model';
import { HttpService } from './common/http.service';

@Injectable({
  providedIn: 'root'
})
export class XaService {

  constructor(
    private _httpService: HttpService,
  ) { }

  async saveData(model: Xa) {
    const url = '/Xa/insert';
    return this._httpService.post(url, model).toPromise();
  }

  async getAll() {
    const url = '/Xa/get-all';
    const resp = await this._httpService.get(url).toPromise();
    return resp.data;
  }

  async filter(id: any) {
    const url = '/Xa/filter';
    const resp = await this._httpService.post(url, id).toPromise();
    return resp.data;
  }
}
