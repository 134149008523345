import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'app/modules/admin/models/menu/menu-item.model';


@Component({
  selector: 'app-left-menu',
  templateUrl: './left-menu.component.html',
  styleUrls: ['./left-menu.component.scss']
})
export class LeftMenuComponent implements OnInit {

  public menus: Array<MenuItem>;
  constructor() { }

  ngOnInit() {

    if (localStorage.getItem('_menu') === null) {
      const menu1: MenuItem = new MenuItem();
      menu1.href = '/dashboard';
      menu1.name = 'Quản lý danh mục';
      menu1.icon = 'mdi mdi-view-dashboard';
      menu1.children = [];

      const menu2: MenuItem = new MenuItem();
      menu2.href = '/emulation-reward';
      menu2.name = 'Quản lý khen thưởng';
      menu2.icon = 'mdi mdi-chart-bubble';
      menu2.children = [];

      const menu3: MenuItem = new MenuItem();
      menu3.href = '/chuc-vu';
      menu3.name = 'Quản lý chức vụ';
      menu3.icon = 'fas fa-building';
      menu3.children = [];

      const menu4: MenuItem = new MenuItem();
      menu4.href = '/cap-khen-thuong';
      menu4.name = 'Quản lý cấp khen thưởng';
      menu4.icon = 'fas fa-map-signs';
      menu4.children = [];

      const menu5: MenuItem = new MenuItem();
      menu5.href = '/don-vi';
      menu5.name = 'Quản lý đơn vị';
      menu5.icon = 'fas fa-box';
      menu5.children = [];

      const menu6: MenuItem = new MenuItem();
      menu6.href = '/hinh-thuc-khen-thuong';
      menu6.name = 'Quản lý hình thức khen thưởng';
      menu6.icon = 'fas fa-book';
      menu6.children = [];

      const menu7: MenuItem = new MenuItem();
      menu7.href = '/khen-thuong';
      menu7.name = 'Quản lý danh hiệu';
      menu7.icon = 'fas fa-bullhorn';
      menu7.children = [];

      const menu8: MenuItem = new MenuItem();
      menu8.href = '/doi-tuong';
      menu8.name = 'Quản lý đối tượng';
      menu8.icon = 'fas fa-address-book';
      menu8.children = [];

      const menu9: MenuItem = new MenuItem();
      menu9.href = '/danh-xung';
      menu9.name = 'Quản lý danh xưng';
      menu9.icon = 'fas fa-fax';
      menu9.children = [];

      const menu10: MenuItem = new MenuItem();
      menu9.href = '/bao-cao';
      menu9.name = 'Báo cáo';
      menu9.icon = 'fas fa-file';
      menu9.children = [];

      const menu: MenuItem = new MenuItem();
      menu.href = '/quan-ly-tai-khoan';
      menu.name = 'Quản lý tài khoản';
      menu.icon = 'fas fa-user';
      menu.children = [];

      const listMenu: Array<MenuItem> = new Array<MenuItem>();
      const userInfo: any = JSON.parse(localStorage.getItem('user_info'));
      if (userInfo.roleId && userInfo.roleId === 2) {
        listMenu.push(menu6);
      } else {
        listMenu.push(menu2);
        listMenu.push(menu3);
        listMenu.push(menu4);
        listMenu.push(menu5);
        listMenu.push(menu6);
        listMenu.push(menu7);
        listMenu.push(menu8);
        listMenu.push(menu9);
        listMenu.push(menu10);
        listMenu.push(menu);
      }
      const stringMenu = JSON.stringify(listMenu);
      localStorage.setItem('_menu', stringMenu);
    }


    this.menus = JSON.parse(localStorage.getItem('_menu'));


  }

}
