import { SelectModel } from "app/models/common/select.model";

export class UserModel extends SelectModel{
    public id: number;
    public userName: string;
    public fullName: string;
    public email: string;
    public address: string;
    public roleId = 2;
    public roleName: string;
    public password: string;
}
