
function _window(): any {
    // return the global native browser window object
    return window;
}

export class ApiConfigModel {
    public server: string;
    public avatarPath: string;
    public defaultAvatarPath: string;
    public logoPath: string;
    public defaultLogoPath: string;
    public apiPrefix: string;
    public tinhLaoCaiId: string;
    public get url(): string { return `${this.server}/${this.apiPrefix}`; }

    // public constructor() {
    //     const config = _window().__appConfig__;

    //     this.server = config.server;
    //     this.avatarPath = config.avatarPath;
    //     this.defaultAvatarPath = config.defaultAvatarPath;
    //     this.logoPath = config.logoPath;
    //     this.defaultLogoPath = config.defaultLogoPath;
    //     this.apiPrefix = config.apiPrefix;
    // }

}
