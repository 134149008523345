import { Injectable } from '@angular/core';
import { HttpService } from './common/http.service';
import { LoaiDonVi } from '../models/loai-don-vi.model';

@Injectable({
  providedIn: 'root'
})
export class LoaiDonViService {

  constructor(
    private _httpService: HttpService,
  ) { }

  async saveData (model: LoaiDonVi) {
    const url = '/LoaiDonVi/insert';
    return this._httpService.post(url, model).toPromise();
  }

  async getAll () {
    const url = '/LoaiDonVi/get-all';
    const resp = await this._httpService.get(url).toPromise();
    return resp.data;
  }
}
