import { Component, OnInit, AfterViewInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material/dialog';
import { SubdivisionsComponent } from '../subdivisions/subdivisions.component';
import { ObjectsComponent } from '../objects/objects.component';
import { PositionComponent } from '../position/position.component';
import { TitleComponent } from '../title/title.component';
import { FormOfRewardComponent } from '../form-of-reward/form-of-reward.component';
import { FieldOfActivityComponent } from '../field-of-activity/field-of-activity.component';
import { UnitTypeComponent } from '../unit-type/unit-type.component';
import { RewardModeComponent } from '../reward-mode/reward-mode.component';
import { NatureGiftsComponent } from '../nature-gifts/nature-gifts.component';
import { DecisionToRewardComponent } from '../decision-to-reward/decision-to-reward.component';
import { LoginService } from '../../services/login.service';
declare const $: any;

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    // providers: [LoginService]
})
export class DashboardComponent implements OnInit {
    constructor(
        public dialog: MatDialog,
        // public loginService: LoginService
        ) {}
    public async ngOnInit() {

// const response = await this.loginService.loginUser(null);
// response.isSuccess()
// response.data;
     }

    addSubdivisions(): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.hasBackdrop = false;
        dialogConfig.width = '900px';
        const dialogRef = this.dialog.open(SubdivisionsComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(result => {
            console.log('Dialog result:');
          });
    }

    addObjects(): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.hasBackdrop = false;
        dialogConfig.width = '900px';
        const dialogRef = this.dialog.open(ObjectsComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(result => {
            console.log('Dialog result:');
          });
    }

    addPosition(): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.hasBackdrop = false;
        dialogConfig.width = '900px';
        const dialogRef = this.dialog.open(PositionComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(result => {
            console.log('Dialog result:');
          });
    }

    addTitle(): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.hasBackdrop = false;
        dialogConfig.width = '900px';
        const dialogRef = this.dialog.open(TitleComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(result => {
            console.log('Dialog result:');
          });
    }

    addFormOfReward(): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.hasBackdrop = false;
        dialogConfig.width = '900px';
        const dialogRef = this.dialog.open(FormOfRewardComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(result => {
            console.log('Dialog result:');
          });
    }

    addFieldOfActivity(): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.hasBackdrop = false;
        dialogConfig.width = '900px';
        const dialogRef = this.dialog.open(FieldOfActivityComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(result => {
            console.log('Dialog result:');
          });
    }

    addUnitType(): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.hasBackdrop = false;
        dialogConfig.width = '900px';
        const dialogRef = this.dialog.open(UnitTypeComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(result => {
            console.log('Dialog result:');
          });
    }

    addRewardMode(): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.hasBackdrop = false;
        dialogConfig.width = '900px';
        const dialogRef = this.dialog.open(RewardModeComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(result => {
            console.log('Dialog result:');
          });
    }

    addNatureGifts(): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.hasBackdrop = false;
        dialogConfig.width = '900px';
        const dialogRef = this.dialog.open(NatureGiftsComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(result => {
            console.log('Dialog result:');
          });
    }

    addDecisionToReward(): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.hasBackdrop = false;
        dialogConfig.width = '900px';
        dialogConfig.maxHeight = '800px';
        const dialogRef = this.dialog.open(DecisionToRewardComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(result => {
            console.log('Dialog result:');
          });
    }
}
