export class PageDTO {
    offset = 0;
    // The total number of pages
    totalItems = DEFAULT_PAGE_LIMIT;
    limit = DEFAULT_PAGE_LIMIT;
    sortName = "";
    sortAsc = false;
}


export const DEFAULT_PAGE_LIMIT = 50;
