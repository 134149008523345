declare var jQuery: any;
import { Component, OnInit, AfterViewInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DoiTuong } from '../../models/doi-tuong.model';
import { ChucVu } from '../../models/chuc-vu.model';
import { ChucDanh } from '../../models/chuc-danh.model';
import { DonVi } from '../../models/don-vi.model';
import { HinhThucKhenThuong } from '../../models/hinh-thuc-khen-thuong.model';
import { PhuongThucKhenThuong } from '../../models/phuong-thuc-khen-thuong.model';
import { LinhVucHoatDong } from '../../models/linh-vuc-hoat-dong.model';
import { TinhChatTang } from '../../models/tinh-chat-tang.model';
import { QuyetDinhKhenThuong } from '../../models/quyet-dinh-khen-thuong/quyet-dinh-khen-thuong.model';
import { DoiTuongService } from '../../services/doi-tuong.service';
import { ChucVuService } from '../../services/chuc-vu.service';
import { ChucDanhService } from '../../services/chuc-danh.service';
import { DonViService } from '../../services/don-vi.service';
import { HinhThucKhenThuongService } from '../../services/hinh-thuc-khen-thuong.service';
import { PhuongThucKhenThuongService } from '../../services/phuong-thuc-khen-thuong.service';
import { LinhVucHoatDongService } from '../../services/linh-vuc-hoat-dong.service';
import { TinhChatTangService } from '../../services/tinh-chat-tang.service';
import { QuyetDinhKhenThuongService } from '../../services/quyet-dinh-khen-thuong.service';
import { DanhXungService } from '../../services/danh-xung.service';
import { LoaiCanBoService } from '../../services/loai-can-bo.service';
import { TinhService } from '../../services/tinh.service';
import { HuyenService } from '../../services/huyen.service';
import { XaService } from '../../services/xa.service';
import { CapKhenThuongService } from '../../services/cap-khen-thuong.service';
import { KhenThuongService } from '../../services/khen-thuong.service';
import { MucGiaiThuongService } from '../../services/muc-giai-thuong.service';
import { DanhXung } from '../../models/danh-xung.model';
import { LoaiCanBo } from '../../models/loai-can-bo.model';
import { Tinh } from '../../models/tinh.model';
import { Huyen } from '../../models/Huyen/huyen.model';
import { Xa } from '../../models/Xa/xa.model';
import { CapKhenThuong } from '../../models/cap-khen-thuong.model';
import { KhenThuong } from '../../models/khen-thuong.model';
import { MucGiaiThuong } from '../../models/muc-giai-thuong.model';
import { HuyenCriteria } from '../../models/Huyen/HuyenCriteria';
import { XaCriteria } from '../../models/Xa/XaCriteria';
import { LoaiKhenThuong } from '../../models/loai-khen-thuong.model';
import { LoaiKhenThuongService } from '../../services/loai-khen-thuong.service';
import { AppConfigModel } from 'app/models/config/app-config.model';

@Component({
  selector: 'app-decision-to-reward',
  templateUrl: './decision-to-reward.component.html',
  styleUrls: ['./decision-to-reward.component.scss']
})
export class DecisionToRewardComponent implements AfterViewInit {

  public doiTuong: Array<DoiTuong>;
  public danhXung: Array<DanhXung>;
  public chucVu: Array<ChucVu>;
  public loaiCanBo: Array<LoaiCanBo>;
  public donVi: Array<DonVi>;
  public tinh: Array<Tinh>;
  public huyen: Array<Huyen>;
  public xa: Array<Xa>;
  public capKhenThuong: Array<CapKhenThuong>;
  public khenThuong: Array<KhenThuong>;
  public mucGiaiThuong: Array<MucGiaiThuong>;
  public detailData: QuyetDinhKhenThuong;
  public hinhThucKhenThuong: Array<HinhThucKhenThuong>;

  public huyenFilter: HuyenCriteria;
  public xaFilter: XaCriteria;
  public loaiKhenThuong: Array<LoaiKhenThuong>;
  public isShow: boolean;

  public filesUpload: any;
  public pathDownLoad: string;
  public title: string;

  public titleAdd = 'Thêm quyết định khen thưởng';
  public titleUpdate = 'Cập nhật quyết định khen thưởng';
  public titleView = 'Xem quyết định khen thưởng';


  constructor(
    public dialogRef: MatDialogRef<DecisionToRewardComponent>,
    public quyetDinhKhenThuongService: QuyetDinhKhenThuongService,
    public doiTuongService: DoiTuongService,
    public danhXungService: DanhXungService,
    public chucVuService: ChucVuService,
    public loaiCanBoService: LoaiCanBoService,
    public donViService: DonViService,
    public tinhService: TinhService,
    public huyenService: HuyenService,
    public xaService: XaService,
    public capKhenThuongService: CapKhenThuongService,
    public khenThuongService: KhenThuongService,
    public mucGiaiThuongService: MucGiaiThuongService,
    public hinhThucKhenThuongService: HinhThucKhenThuongService,
    public loaiKhenThuongService: LoaiKhenThuongService,
    @Inject(MAT_DIALOG_DATA) public data: QuyetDinhKhenThuong

  ) {
    this.detailData = new QuyetDinhKhenThuong();
    this.huyenFilter = new HuyenCriteria();
    this.xaFilter = new XaCriteria();
    this.loaiKhenThuong = new Array<LoaiKhenThuong>();
    this.isShow = false;
    this.title = 'Thêm quyết định khen thưởng';
  }

  async ngAfterViewInit() {
    // this.detailData.maTinh = AppConfigModel.ApiConfig.tinhLaoCaiId;
    // console.log('TInh', AppConfigModel.ApiConfig.tinhLaoCaiId);
    /*datwpicker*/
    // jQuery('.mydatepicker').datepicker({
    //   format: 'dd/mm/yyyy',
    // });
    setTimeout(() => {
      jQuery('.select2').select2();
    }, 1000);

    const seft = this;
    jQuery('#tinhDetail').on(
      'change',
      async (e) => {
        if (!(jQuery('#tinhDetail').val() == null || jQuery('#tinhDetail').val() === '-1' || jQuery('#tinhDetail').val() === '')) {
          seft.huyenFilter.maTinh = jQuery('#tinhDetail').val().split(':')[1].trim();
          seft.huyen = await this.huyenService.filter(seft.huyenFilter);
        } else {
          seft.huyen = [];
          seft.xa = [];
        }
      }
    );

    jQuery('#huyenDetail').on(
      'change',
      async (e) => {
        if (!(jQuery('#huyenDetail').val() == null || jQuery('#huyenDetail').val() === '-1' || jQuery('#huyenDetail').val() === '')) {
          seft.xaFilter.maHuyen = jQuery('#huyenDetail').val().split(':')[1].trim();
          seft.xa = await this.xaService.filter(seft.xaFilter);
        }
      }
    );

    await Promise.all([
      this.doiTuongService.getAll(),
      this.danhXungService.getAll(),
      this.chucVuService.getAll(),
      this.loaiCanBoService.getAll(),
      this.donViService.getAll(),
      this.tinhService.getAll(),
      this.capKhenThuongService.getAll(),
      this.khenThuongService.filterById(1),
      this.mucGiaiThuongService.getAll(),
      this.hinhThucKhenThuongService.getAll(),
      this.loaiKhenThuongService.getAll()
    ])
      .then((res) => {
        let i = 0;
        this.doiTuong = res[i++];
        this.danhXung = res[i++];
        this.chucVu = res[i++];
        this.loaiCanBo = res[i++];
        this.donVi = res[i++];
        this.tinh = res[i++];
        // this.huyen = res[i++];
        // this.xa = res[i++];
        this.capKhenThuong = res[i++];
        this.khenThuong = res[i++];
        this.mucGiaiThuong = res[i++];
        this.hinhThucKhenThuong = res[i++];
        this.loaiKhenThuong = res[i++];
        setTimeout(() => {
          jQuery('#tinhDetail').select2();
        }, 1000);
      });
    this.huyenFilter.maTinh = this.detailData.maTinh;
    this.huyen = await this.huyenService.filter(this.huyenFilter);
    if (this.data != null) {
      if (this.data.readOnly === true) {
        this.title = this.titleView;
      } else {
        this.title = this.titleUpdate;
      }

      this.detailData = this.data;
      // setTimeout(() => {
      //   jQuery('#tinhDetail').select2();
      // }, 1000);
      this.pathDownLoad = AppConfigModel.ApiConfig.server + '/Upload/' + this.data.attachment;
      this.huyenFilter.maTinh = this.detailData.maTinh;
      this.xaFilter.maHuyen = this.detailData.maHuyen;

      if (this.detailData.maHuyen) {
        this.huyen = await this.huyenService.filter(this.huyenFilter);
      }

      if (this.detailData.maXa) {
        this.xa = await this.xaService.filter(this.xaFilter);
      }

      // loai khen thuong
      const idLoaiKhenThuong: number = await this.khenThuongService.getIdLoaiKhenThuong(this.detailData.maKhenThuong);
      if (idLoaiKhenThuong === 2) {
        await this.onChangeLoaiKhenThuong(idLoaiKhenThuong.toString());
        setTimeout(() => {
          jQuery('#loaikhenthuongD').val(idLoaiKhenThuong.toString());
        }, 1000);
      }

    }
  }
  // true : luu va them; false: luu
  async save(continues: boolean) {
    // tslint:disable-next-line:max-line-length
    const maDoiTuong = (jQuery('#doituongD').val() == null || jQuery('#doituongD').val() === '-1') ? null : Number(jQuery('#doituongD').val().split(':')[1]);
    // tslint:disable-next-line:max-line-length
    const maDanhXung = (jQuery('#danhxungD').val() == null || jQuery('#danhxungD').val() === '-1') ? null : Number(jQuery('#danhxungD').val().split(':')[1]);
    // tslint:disable-next-line:max-line-length
    // tslint:disable-next-line:max-line-length
    const chucvuD = (jQuery('#chucvuD').val() == null || jQuery('#chucvuD').val() === '-1') ? null : Number(jQuery('#chucvuD').val().split(':')[1]);
    // tslint:disable-next-line:max-line-length
    const maLoaiCanBo = (jQuery('#loaicanboD').val() == null || jQuery('#loaicanboD').val() === '-1') ? null : Number(jQuery('#loaicanboD').val().split(':')[1]);
    // tslint:disable-next-line:max-line-length
    const maDonVi = (jQuery('#donviD').val() == null || jQuery('#donviD').val() === '-1') ? null : Number(jQuery('#donviD').val().split(':')[1]);
    // tslint:disable-next-line:max-line-length
    const maTinh: string = (jQuery('#tinhDetail').val() == null || jQuery('#tinhDetail').val() === '-1') ? null : jQuery('#tinhDetail').val().split(':')[1].trim();
    // tslint:disable-next-line:max-line-length
    const maHuyen: string = (jQuery('#huyenDetail').val() == null || jQuery('#huyenDetail').val() === '-1') ? null : jQuery('#huyenDetail').val().split(':')[1].trim();
    // tslint:disable-next-line:max-line-length
    const maXa: string = (jQuery('#xaDetail').val() == null || jQuery('#xaDetail').val() === '-1') ? null : jQuery('#xaDetail').val().split(':')[1].trim();
    // tslint:disable-next-line:max-line-length
    //  const namKhen = (jQuery('#namkhenf').val() == null || jQuery('#namkhenf').val() === '-1') ? -1 : Number(jQuery('#namkhenf').val().split(':')[1]);
    // tslint:disable-next-line:max-line-length
    const maCap = (jQuery('#capkhenthuongD').val() == null || jQuery('#capkhenthuongD').val() === '-1') ? null : Number(jQuery('#capkhenthuongD').val().split(':')[1]);
    // tslint:disable-next-line:max-line-length
    const maHinhThuc = (jQuery('#hinhthuckhenthuongD').val() == null || jQuery('#hinhthuckhenthuongD').val() === '-1') ? null : Number(jQuery('#hinhthuckhenthuongD').val().split(':')[1]);
    // tslint:disable-next-line:max-line-length
    const maMucGiaiThuong = (jQuery('#mucgiaithuongD').val() == null || jQuery('#mucgiaithuongD').val() === '-1') ? null : Number(jQuery('#mucgiaithuongD').val().split(':')[1]);
    // tslint:disable-next-line:max-line-length
    const khenthuongD = (jQuery('#khenthuongD').val() == null || jQuery('#khenthuongD').val() === '-1') ? null : Number(jQuery('#khenthuongD').val().split(':')[1]);
    const idLoaiKhenThuong = jQuery('#loaikhenthuongD').val();
    if (idLoaiKhenThuong === '1') {
      this.detailData.maMucGiaiThuong = null;
    }
    this.detailData.maDoiTuong = maDoiTuong;
    this.detailData.maDanhXung = maDanhXung;
    this.detailData.maChucVu = chucvuD;
    this.detailData.maLoaiCanBo = maLoaiCanBo;
    this.detailData.maDonVi = maDonVi;
    this.detailData.maTinh = maTinh;
    this.detailData.maHuyen = maHuyen;
    this.detailData.maXa = maXa;
    this.detailData.maCap = maCap;
    this.detailData.maHinhThuc = maHinhThuc;
    this.detailData.maMucGiaiThuong = maMucGiaiThuong;
    this.detailData.maKhenThuong = khenthuongD;


    const fd = new FormData();
    fd.append('fileBase', this.filesUpload);
    const filePath: any = await this.quyetDinhKhenThuongService.upload(fd);
    if (filePath.status === 200) {
      this.detailData.attachment = filePath.data;
    }
    if (this.data == null) {
      const resq: any = await this.quyetDinhKhenThuongService.saveData(this.detailData);
      this.handlerSave(continues, resq.data);
    } else {
      const resq: any = await this.quyetDinhKhenThuongService.updateData(this.detailData);
      this.handlerSave(continues, resq.data);
    }

    // this.dialogRef.close();
    // if (resq.isSuccess()) {
    //  this.dialogRef.close();
    // }
  }
  handlerSave(continues: boolean, id: number) {
    if (continues) {
      this.dialogRef.close(true);
    } else {
      this.detailData.maQD = id;
      this.data = this.detailData;
      this.title = 'Cập nhật quyết định khen thưởng';
    }
  }

  async onChangeLoaiKhenThuong(value) {
    if (value === '1') {
      this.isShow = false;
      this.detailData.maMucGiaiThuong = null;
    } else {
      this.isShow = true;
    }
    const id = Number(value);
    this.khenThuong = await this.khenThuongService.filterById(id);
    setTimeout(() => {
      jQuery('#khenthuongD').select2();
      jQuery('#mucgiaithuongD').select2();
    }, 1000);
  }

  onFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.filesUpload = file;
    }
  }

}
