declare var jQuery: any;
import { Component, OnInit, Inject } from '@angular/core';
import { DanhXung } from '../../models/danh-xung.model';
import { DanhXungService } from '../../services/danh-xung.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-danh-xung-modal',
  templateUrl: './danh-xung-modal.component.html',
  styleUrls: ['./danh-xung-modal.component.scss']
})
export class DanhXungModalComponent implements OnInit {

  public detailData: DanhXung;
  public title: string;

  public titleAdd = 'Thêm hình danh xưng';
  public titleUpdate = 'Cập nhật danh xưng';

  constructor(
    public dialogRef: MatDialogRef<DanhXungModalComponent>,
    public services: DanhXungService,
    @Inject(MAT_DIALOG_DATA) public data: DanhXung
  ) {
    this.detailData = new DanhXung();
    if (this.data == null) {
      this.title = this.titleAdd;
    } else {
      this.detailData = this.data;
      this.title = this.titleUpdate;
    }
  }

  ngOnInit() {
  }

  handlerSave(continues: boolean, id: number) {
    if (continues) {
      this.dialogRef.close(true);
    } else {
      this.detailData.maDanhXung = id;
      this.data = this.detailData;
      this.title = this.titleUpdate;
    }
  }

  // true : luu va them; false: luu
  async save(continues: boolean) {
    // save data
    if (this.data == null) {
      const resq: any = await this.services.saveData(this.detailData);
      this.handlerSave(continues, resq.data);
    } else {
      const resq: any = await this.services.updateData(this.detailData);
      this.handlerSave(continues, resq.data);
    }
  }

}
