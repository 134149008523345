import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bao-cao',
  templateUrl: './bao-cao.component.html',
  styleUrls: ['./bao-cao.component.scss']
})
export class BaoCaoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  onClickReportByCoQuan(): void {
    this.downloadReport("thong-ke-khen-thuong-co-quan");
  }

  onClickReportByDoanhNghiep(): void {
    this.downloadReport("thong-ke-khen-thuong-doanh-nghiep");
  }

  onClickReportByToChucKhac(): void {
    this.downloadReport("thong-ke-khen-thuong-to-chuc-khac");
  }

  onClickReportByHuyen(): void {
    this.downloadReport("thong-ke-theo-huyen");
  }

  onClickReportByDanhHieu(): void {
    this.downloadReport("thong-ke-theo-danh-hieu");
  }

  onClickReportByHinhThuc(): void {
    this.downloadReport("thong-ke-hinh-thuc-khen-thuong");
  }

  downloadReport(fileName): void {
    window.open(`assets/report/${fileName}.xlsx`, "_blank");
  }
}
