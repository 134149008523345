import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class UserLoginGuard implements CanActivate {
  constructor(private router: Router) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    //  const islogged = ;
    if (localStorage.getItem('access_token') == null) {
      this.router.navigate(['/login']);
    }
    const userInfo: any = JSON.parse(localStorage.getItem('user_info'));
    if (userInfo.roleId && userInfo.roleId === 2 ) {
      const urlRoute = state.url.substring(1);
      if (urlRoute.localeCompare('') === 0) {
          return true;
       } else if (urlRoute.localeCompare('emulation-reward') === 0) {
         return true;
       } else {
         return false;
       }
    }
    return true;
  }
}
