import { SelectModel } from "app/models/common/select.model";

export class KhenThuong extends SelectModel{
    maKhenThuong: number;
    tenKhenThuong: string;
    maLoaiKhenThuong: number;
    // custom prop
    tenLoaiKhenThuong: string;

}
