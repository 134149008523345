import { Injectable } from "@angular/core";
import { HttpService } from "./common/http.service";
import { HinhThucKhenThuong } from "../models/hinh-thuc-khen-thuong.model";
import { AppConfigModel } from "app/models/config/app-config.model";
import { HttpClient } from "@angular/common/http";
import { Criteria } from "../models/common/criteria";

@Injectable({
  providedIn: "root"
})
export class HinhThucKhenThuongService {

  constructor(
    private _httpService: HttpService,
    private http: HttpClient
  ) { }

  async saveData(model: HinhThucKhenThuong) {
    const url = "/HinhThucKhenThuong/insert";
    return this._httpService.post(url, model).toPromise();
  }

  async getAll() {
    const url = "/HinhThucKhenThuong/get-all";
    const resp = await this._httpService.get(url).toPromise();
    return resp.data;
  }

  // update
  async updateData(model: HinhThucKhenThuong) {
    const url = "/HinhThucKhenThuong/update";
    return this._httpService.post(url, model).toPromise();
  }

  // delete
  async delete(model: HinhThucKhenThuong) {
    const url = "/HinhThucKhenThuong/delete";
    return this._httpService.post(url, model).toPromise();
  }

  async deletes(model: any) {
    const url = "/HinhThucKhenThuong/deletes";
    return this._httpService.post(url, model).toPromise();
  }

  async filter(criteria: Criteria) {
    const url = "/HinhThucKhenThuong/filter";
    const resp = await this._httpService.post(url, criteria).toPromise();
    return resp.data;
  }
}
