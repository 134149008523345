declare var jQuery: any;
import { Component, OnInit, AfterViewInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DoiTuong } from '../../models/doi-tuong.model';
import { DoiTuongService } from '../../services/doi-tuong.service';


@Component({
  selector: 'app-objects',
  templateUrl: './objects.component.html',
  styleUrls: ['./objects.component.scss']
})
export class ObjectsComponent implements OnInit {

  public detailData: DoiTuong;
  public title: string;

  public titleAdd = 'Thêm đối tượng';
  public titleUpdate = 'Cập nhật đối tượng';

  constructor(
    public dialogRef: MatDialogRef<ObjectsComponent>,
    public services: DoiTuongService,
    @Inject(MAT_DIALOG_DATA) public data: DoiTuong
  ) {
    this.detailData = new DoiTuong();
    if (this.data == null) {
      this.title = this.titleAdd;
    } else {
      this.detailData = this.data;
      this.title = this.titleUpdate;
    }
  }

  ngOnInit() {
  }

  handlerSave(continues: boolean, id: number) {
    if (continues) {
      this.dialogRef.close(true);
    } else {
      this.detailData.maDoiTuong = id;
      this.data = this.detailData;
      this.title = this.titleUpdate;
    }
  }

  // true : luu va them; false: luu
  async save(continues: boolean) {
    // save data
    if (this.data == null) {
      const resq: any = await this.services.saveData(this.detailData);
      this.handlerSave(continues, resq.data);
    } else {
      const resq: any = await this.services.updateData(this.detailData);
      this.handlerSave(continues, resq.data);
    }
  }
}
