<!-- Left Sidebar - style you can find in sidebar.scss  -->
        <!-- ============================================================== -->
        <aside class="left-sidebar" data-sidebarbg="skin5">
            <!-- Sidebar scroll-->
            <div class="scroll-sidebar">
                <!-- Sidebar navigation-->
                <nav class="sidebar-nav">
                    <ul id="sidebarnav" class="p-t-30">                        
                            <li class="sidebar-item" *ngFor="let item of menus"> 
                                <a [ngClass]="{'sidebar-link has-arrow waves-effect waves-dark': item.children.length > 0, 'sidebar-link waves-effect waves-dark sidebar-link': item.children.length == 0}"
                                    routerLink="{{item.href}}"  href="{{item.href}}" aria-expanded="false">
                                    <i class="{{item.icon}}"></i><span class="hide-menu">{{item.name}} </span>
                                </a>
                                <ul *ngIf="item.children.length > 0" aria-expanded="false" class="collapse  first-level">
                                    <li class="sidebar-item" *ngFor="let itemcd of item.children">
                                        <a routerLink="{{itemcd.href}}" href="{{itemcd.href}}" class="sidebar-link"><i class="{{itemcd.icon}}"></i><span class="hide-menu"> {{itemcd.name}}</span></a>
                                    </li>
                                    
                                </ul>
                            </li>                          
                    </ul>
                </nav>
                <!-- End Sidebar navigation -->
            </div>
            <!-- End Sidebar scroll-->
        </aside>
        <!-- ============================================================== -->
        <!-- End Left Sidebar - style you can find in sidebar.scss  -->
        <!-- ============================================================== -->