<!-- <div class="pagination-container">
    <ul class="pagination">
        <li class="paginate_button page-item previous">
            <a aria-controls="zero_config" class="page-link">Trước</a></li>
        <li class="paginate_button page-item next" id="zero_config_next">
            <a aria-controls="zero_config" class="page-link">Sau</a></li>
    </ul>
</div> -->
<div class="pagination-container">
    <ul ng-if="page.limit" class="pagination">
        <li [ngClass]="{disabled:currentPageIndex <= 1}" class="paginate_button page-item previous">
            <a href="javascript:;" (click)="(currentPageIndex > 1) && goToPage(1)" aria-controls="datatable-buttons"
                class="page-link">
                Đầu</a>
        </li>

        <li [ngClass]="{disabled:currentPageIndex <= 1}" class="paginate_button page-item previous">
            <a href="javascript:;" (click)="(currentPageIndex > 1) && goToPage(currentPageIndex-1)"
                aria-controls="datatable-buttons" class="page-link">
                Trước</a>
        </li>

        <li *ngFor="let idx of pageIndexes" [ngClass]="{'disabled':currentPageIndex===idx, 'current-page':currentPageIndex===idx}"
            class="paginate_button page-item previous">
            <a href="javascript:;" (click)="goToPage(idx)" aria-controls="datatable-buttons" class="page-link">
                {{idx}}</a>
        </li>

        <li [ngClass]="{disabled:currentPageIndex >= totalPageCount}" class="paginate_button page-item next">
            <a href="javascript:;" (click)="(currentPageIndex < totalPageCount) && goToPage(currentPageIndex+1)"
                aria-controls="datatable-buttons" class="page-link">Sau</a>
        </li>

        <li [ngClass]="{disabled:currentPageIndex >= totalPageCount}" class="paginate_button page-item next">
            <a href="javascript:;" (click)="(currentPageIndex < totalPageCount) && goToPage(totalPageCount)"
                aria-controls="datatable-buttons" class="page-link">Cuối</a>
        </li>
    </ul>
</div>