<div class="page-wrapper">
  <!-- ============================================================== -->
  <!-- Bread crumb and right sidebar toggle -->
  <!-- ============================================================== -->
   <div class="page-breadcrumb">
      <div class="row">
          <div class="col-12 d-flex no-block align-items-center">
              <h4 class="page-title">Dashboard</h4>
              <div class="ml-auto text-right">
                  <nav aria-label="breadcrumb">
                      <ol class="breadcrumb">
                          <li class="breadcrumb-item"><a href="#">Home</a></li>
                          <li class="breadcrumb-item active" aria-current="page">Library</li>
                      </ol>
                  </nav>
              </div>
          </div>
      </div>
  </div>
  <!-- ============================================================== -->
  <!-- End Bread crumb and right sidebar toggle -->
  <!-- ============================================================== -->
  <!-- ============================================================== -->
  <!-- Container fluid  -->
  <!-- ============================================================== -->
  <div class="container-fluid">
      <!-- ============================================================== -->
      <!-- Sales Cards  -->
      <!-- ============================================================== -->
      <div class="row">
          <!-- Column -->
          <div class="col-md-6 col-lg-2 col-xlg-3" (click)="addSubdivisions()">
              <div class="card card-hover">
                  <div class="box bg-cyan text-center">
                      <h1 class="font-light text-white"><i class="mdi mdi-archive"></i></h1>
                      <h6 class="text-white">Thêm đơn vị</h6>
                  </div>
              </div>
          </div>
          <!-- Column -->
          <div class="col-md-6 col-lg-4 col-xlg-3"(click)="addObjects()">
              <div class="card card-hover">
                  <div class="box bg-success text-center">
                      <h1 class="font-light text-white"><i class="mdi mdi-account-location"></i></h1>
                      <h6 class="text-white">Thêm đối tượng</h6>
                  </div>
              </div>
          </div>
           <!-- Column -->
          <div class="col-md-6 col-lg-2 col-xlg-3"(click)="addPosition()">
              <div class="card card-hover">
                  <div class="box bg-warning text-center">
                      <h1 class="font-light text-white"><i class="mdi mdi-collage"></i></h1>
                      <h6 class="text-white">Chức vụ</h6>
                  </div>
              </div>
          </div>
          <!-- Column -->
          <div class="col-md-6 col-lg-2 col-xlg-3"(click)="addTitle()">
              <div class="card card-hover">
                  <div class="box bg-danger text-center">
                      <h1 class="font-light text-white"><i class="mdi mdi-border-outside"></i></h1>
                      <h6 class="text-white">Chức danh</h6>
                  </div>
              </div>
          </div>
          <!-- Column -->
          <div class="col-md-6 col-lg-2 col-xlg-3" (click)="addFieldOfActivity()">
              <div class="card card-hover">
                  <div class="box bg-info text-center">
                      <h1 class="font-light text-white"><i class="mdi mdi-arrow-all"></i></h1>
                      <h6 class="text-white">Linh vực hoạt động</h6>
                  </div>
              </div>
          </div>
          <!-- Column -->
          <!-- Column -->
          <div class="col-md-6 col-lg-4 col-xlg-3"(click)="addFormOfReward()">
              <div class="card card-hover">
                  <div class="box bg-danger text-center">
                      <h1 class="font-light text-white"><i class="mdi mdi-receipt"></i></h1>
                      <h6 class="text-white">Hình thức khen thưởng</h6>
                  </div>
              </div>
          </div>
          <!-- Column -->
          <div class="col-md-6 col-lg-2 col-xlg-3" (click)="addUnitType()">
              <div class="card card-hover">
                  <div class="box bg-info text-center">
                      <h1 class="font-light text-white"><i class="mdi mdi-relative-scale"></i></h1>
                      <h6 class="text-white">Loại đơn vị</h6>
                  </div>
              </div>
          </div>
           <!-- Column -->
          <div class="col-md-6 col-lg-2 col-xlg-3" (click)="addRewardMode()">
              <div class="card card-hover">
                  <div class="box bg-cyan text-center">
                      <h1 class="font-light text-white"><i class="mdi mdi-pencil"></i></h1>
                      <h6 class="text-white">Phương thức khen thưởng</h6>
                  </div>
              </div>
          </div>
          <!-- Column -->
          <div class="col-md-6 col-lg-2 col-xlg-3"(click)="addNatureGifts()">
              <div class="card card-hover">
                  <div class="box bg-success text-center">
                      <h1 class="font-light text-white"><i class="mdi mdi-calendar-check"></i></h1>
                      <h6 class="text-white">Tính chất tặng</h6>
                  </div>
              </div>
          </div>
          <!-- Column -->
          <!-- <div class="col-md-6 col-lg-2 col-xlg-3">
              <div class="card card-hover">
                  <div class="box bg-warning text-center">
                      <h1 class="font-light text-white"><i class="mdi mdi-alert"></i></h1>
                      <h6 class="text-white">Phương thức khen tặng</h6>
                  </div>
              </div>
          </div> -->
          <!-- Column -->
          <!-- Column -->
          <div class="col-md-12 col-lg-12 col-xlg-12"(click)="addDecisionToReward()">
            <div class="card card-hover">
                <div class="box bg-danger text-center">
                    <h1 class="font-light text-white"><i class="mdi mdi-border-outside"></i></h1>
                    <h6 class="text-white">Quyết định khen thưởng</h6>
                </div>
            </div>
        </div>
      </div>
  </div>
  <!-- ============================================================== -->
  <!-- End Container fluid  -->
  <!-- ============================================================== -->
  <!-- ============================================================== -->
</div>