import { Component, OnInit, ElementRef, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import swal from 'sweetalert2';
import { LoginService } from 'app/modules/admin/services/login.service';
import { UserLoginModel } from 'app/modules/admin/models/user/user-login.model';

declare var $: any;

const toast = (swal as any).mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 2000
});

@Component({
    selector: 'app-login-cmp',
    templateUrl: './login.component.html',
    providers: [LoginService]
})
export class LoginComponent implements OnInit, OnDestroy {
    loginForm: FormGroup;
    loading = false;
    submitted = false;
    returnUrl: string;
    error: string;

    constructor(
        private loginService: LoginService,
        private _formBuilder: FormBuilder,
        private _router: Router
    ) {
        this.loginForm = this._formBuilder.group({
            username: ['', Validators.required],
            password: ['', Validators.required]
        });
    }

    ngOnInit() {
        // const card = document.getElementsByClassName('card')[0];
        // setTimeout(function() {
        //   card.classList.remove('card-hidden');
        // }, 700);
        localStorage.clear();
    }

    showLogin() {
        toast({
            type: 'success',
            title: 'Đăng nhập thành công'
        });
    }

    get f() {
        return this.loginForm.controls;
    }

    async onLoginSubmit() {
        console.log(this.f.username.value, this.f.password.value);
        this.submitted = true;

        if (this.loginForm.invalid) {
            console.log('error');
        }
        this.loading = true;
        const loginModel: UserLoginModel = {
            userName: this.f.username.value,
            password: this.f.password.value
        };
        // TODO Chú ý await:
        
        try {
            const result: any = await this.loginService.login(loginModel);            
            window.location.href = '/';
        } catch (error) {            
            this.error = 'Đăng nhập thất bại.';
        }
        
        // if (result.isSuccess) {
        //     window.location.href = '/';
        // } else {
        //     this.error = 'Đăng nhập không thành công. Vui lòng kiểm tra tài khoản';
        // }

    }

    ngOnDestroy() {
        const body = document.getElementsByTagName('body')[0];
        body.classList.remove('login-page');
        body.classList.remove('off-canvas-sidebar');
    }
}
