import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { PhuongThucKhenThuong } from '../../models/phuong-thuc-khen-thuong.model';
import { PhuongThucKhenTangService } from '../../services/phuong-thuc-khen-tang.service';
import { PhuongThucKhenThuongService } from '../../services/phuong-thuc-khen-thuong.service';

@Component({
  selector: 'app-reward-mode',
  templateUrl: './reward-mode.component.html',
  styleUrls: ['./reward-mode.component.scss']
})
export class RewardModeComponent implements OnInit {

  public detailData: PhuongThucKhenThuong;
  constructor(
    public dialogRef: MatDialogRef<RewardModeComponent>,
    public phuongThucKhenThuongService: PhuongThucKhenThuongService,
  ) {
    this.detailData = new PhuongThucKhenThuong();
  }

  ngOnInit() {
  }
  async save(): Promise<void> {
    // save data
    const resq = await this.phuongThucKhenThuongService.saveData(this.detailData);
    if (resq.isSuccess()) {
     this.dialogRef.close();
    }
  }

}
