declare var jQuery: any;
import { Component, OnInit, AfterViewInit } from "@angular/core";
import { HinhThucKhenThuong } from "../../models/hinh-thuc-khen-thuong.model";
import { HinhThucKhenThuongService } from "../../services/hinh-thuc-khen-thuong.service";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { FormOfRewardComponent } from "../form-of-reward/form-of-reward.component";
import { Criteria } from "../../models/common/criteria";

@Component({
  selector: "app-hinh-thuc-khen-thuong",
  templateUrl: "./hinh-thuc-khen-thuong.component.html",
  styleUrls: ["./hinh-thuc-khen-thuong.component.scss"]
})
export class HinhThucKhenThuongComponent implements OnInit, AfterViewInit {

  public detailData: Array<HinhThucKhenThuong>;
  public listDelete = [];
  /**
      * update
   */
  public filter: Criteria;
  public selectAllRow = false;
  ngAfterViewInit(): void {
  }

  constructor(
    public services: HinhThucKhenThuongService,
    public dialog: MatDialog
  ) {
    this.filter = new Criteria();
    this.detailData = new Array<HinhThucKhenThuong>();
  }

  async ngOnInit() {
    await this.reloadData();
  }

  async delete(model: HinhThucKhenThuong): Promise<void> {
    if (confirm("Bạn muốn xóa bản ghi này")) {
      const resq = await this.services.delete(model);
      await this.reloadData();
    }
  }

  add(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.hasBackdrop = false;
    dialogConfig.width = "900px";
    const dialogRef = this.dialog.open(FormOfRewardComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(async result => {
      if (result === true) {
        this.add();
      } else {
        await this.reloadData();
      }
    });
  }

  update(model: HinhThucKhenThuong): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.hasBackdrop = false;
    dialogConfig.width = "900px";
    dialogConfig.data = model;
    const dialogRef = this.dialog.open(FormOfRewardComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(async result => {
      if (result === true) {
        this.add();
      } else {
        await this.reloadData();
      }
    });
  }
  selectDelete(item) {
    const index = this.listDelete.findIndex(x => x.maHinhThuc === item.maHinhThuc);
    if (index > -1) {
      this.listDelete.splice(index, 1);
    } else {
      this.listDelete.push(item);
    }
  }
  async deleteList() {
    if (confirm("Bạn muốn xóa danh sách bản ghi này")) {
      const resq = await this.services.deletes(this.listDelete);
      await this.reloadData(); ;
    }
  }

  /**
   * update
   */

  async checkAll() {
    this.selectAllRow = !this.selectAllRow;
    if (this.detailData) {
      this.detailData.forEach(p => p.selected = this.selectAllRow);
    }
  }

  async changeCheck(idx) {
    if (this.detailData[idx]) {
      this.detailData[idx].selected = !this.detailData[idx].selected;
      this.selectAllRow = this.detailData.filter(p => p.selected).length === this.detailData.length;
      debugger
    }
  }

  async changeFilterPage(event: number) {
    this.filter.page.offset = event;
    await this.reloadData();
  }

  async changeFilter(fieldName: string, event: any) {
    if (!event || !event.value || !fieldName || !Boolean(this.filter[fieldName])) {
      return;
    }
    this.filter[fieldName] = event.value;
    await this.reloadData();
  }

  async reloadData() {
    const data = await this.services.filter(this.filter);
    if (data == null) {
      return;
    }
    this.detailData = data.rows.map(p => Object.assign(new HinhThucKhenThuong(), p));
    this.filter.page = data.page;
  }
}
