import { Injectable } from '@angular/core';
import { HttpService } from './common/http.service';
import { TinhChatTang } from '../models/tinh-chat-tang.model';
import { AppConfigModel } from 'app/models/config/app-config.model';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TinhChatTangService {

  constructor(
    private _httpService: HttpService,
    private http: HttpClient
  ) { }

  async saveData (model: TinhChatTang) {
    const url =  '/TinhChatTang/insert';
    return this._httpService.post(url, model).toPromise();
  }

  async getAll () {
    const url =  '/TinhChatTang/get-all';
    const resp = await this._httpService.get(url).toPromise();
    return resp.data;

    // return await new Promise<any>((resolve, reject) => {
    //   this.http.get(url).subscribe((response: any) => {
    //     const myObject: TinhChatTang[] = response.data;
    //     resolve(myObject);
    //   });
    // });

  }
}
