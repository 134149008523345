import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TinhChatTang } from '../../models/tinh-chat-tang.model';
import { TinhChatTangService } from '../../services/tinh-chat-tang.service';

@Component({
  selector: 'app-nature-gifts',
  templateUrl: './nature-gifts.component.html',
  styleUrls: ['./nature-gifts.component.scss']
})
export class NatureGiftsComponent implements OnInit {

  public detailData: TinhChatTang;
  constructor(
    public dialogRef: MatDialogRef<NatureGiftsComponent>,
    public tinhChatTangService: TinhChatTangService,
  ) {
    this.detailData = new TinhChatTang();
   }

  ngOnInit() {
  }
  async save(): Promise<void> {
    // save data
    const resq = await this.tinhChatTangService.saveData(this.detailData);
    if (resq.isSuccess()) {
     this.dialogRef.close();
    }
  }

}
