import { Component, OnInit, Inject } from '@angular/core';
import { CapKhenThuong } from '../../models/cap-khen-thuong.model';
import { CapKhenThuongService } from '../../services/cap-khen-thuong.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-cap-khen-thuong-modal',
  templateUrl: './cap-khen-thuong-modal.component.html',
  styleUrls: ['./cap-khen-thuong-modal.component.scss']
})
export class CapKhenThuongModalComponent implements OnInit {

  public detailData: CapKhenThuong;
  public title: string;

  public titleAdd = 'Thêm cấp khen thưởng';
  public titleUpdate = 'Cập nhật cấp khen thưởng';

  constructor(
    public dialogRef: MatDialogRef<CapKhenThuongModalComponent>,
    public services: CapKhenThuongService,
    @Inject(MAT_DIALOG_DATA) public data: CapKhenThuong
  ) {
    this.detailData = new CapKhenThuong();
    if (this.data == null) {
      this.title = this.titleAdd;
    } else {
      this.detailData = this.data;
      this.title = this.titleUpdate;
    }
  }

  ngOnInit() {
  }

  handlerSave(continues: boolean, id: number) {
    if (continues) {
      this.dialogRef.close(true);
    } else {
      this.detailData.maCap = id;
      this.data = this.detailData;
      this.title = this.titleUpdate;
    }
  }

  // true : luu va them; false: luu
  async save(continues: boolean) {
    // save data
    if (this.data == null) {
      const resq: any = await this.services.saveData(this.detailData);
      this.handlerSave(continues, resq.data);
    } else {
      const resq: any = await this.services.updateData(this.detailData);
      this.handlerSave(continues, resq.data);
    }
  }

}
