import { Injectable } from "@angular/core";
import { HttpService } from "./common/http.service";
import { DoiTuong } from "../models/doi-tuong.model";
import { HttpClient } from "@angular/common/http";
import { AppConfigModel } from "app/models/config/app-config.model";
import { Criteria } from "../models/common/criteria";

@Injectable({
  providedIn: "root"
})
export class DoiTuongService {

  constructor(
    private _httpService: HttpService,
  ) { }

  async saveData(model: DoiTuong) {
    const url = "/DoiTuong/insert";
    return this._httpService.post(url, model).toPromise();
  }

  async getAll() {
    const url = "/DoiTuong/get-all";
    const resp = await this._httpService.get(url).toPromise();
    return resp.data;
  }
  // update
  async updateData(model: DoiTuong) {
    const url = "/DoiTuong/update";
    return this._httpService.post(url, model).toPromise();
  }

  // delete
  async delete(model: DoiTuong) {
    const url = "/DoiTuong/delete";
    return this._httpService.post(url, model).toPromise();
  }

  async deletes(model: any) {
    const url = "/DoiTuong/deletes";
    return this._httpService.post(url, model).toPromise();
  }

  async filter(criteria: Criteria) {
    const url = "/DoiTuong/filter";
    const resp = await this._httpService.post(url, criteria).toPromise();
    return resp.data;
  }
}
