import { Injectable } from "@angular/core";
import { KhenThuong } from "../models/khen-thuong.model";
import { HttpService } from "./common/http.service";
import { Criteria } from "../models/common/criteria";

@Injectable({
  providedIn: "root"
})
export class KhenThuongService {

  constructor(
    private _httpService: HttpService,
  ) { }

  async saveData(model: KhenThuong) {
    const url = "/KhenThuong/insert";
    return this._httpService.post(url, model).toPromise();
  }

  async getAll() {
    const url = "/KhenThuong/get-all";
    const resp = await this._httpService.get(url).toPromise();
    return resp.data;
  }

  async filterById(model: any) {
    const url = "/KhenThuong/filterById";
    const resp = await this._httpService.post(url, model).toPromise();
    return resp.data;
  }

  async getIdLoaiKhenThuong(model: any) {
    const url = "/KhenThuong/GetIdLoaiKhenThuong";
    const resp = await this._httpService.post(url, model).toPromise();
    return resp.data;
  }

  // update
  async updateData(model: KhenThuong) {
    const url = "/KhenThuong/update";
    return this._httpService.post(url, model).toPromise();
  }

  // delete
  async delete(model: KhenThuong) {
    const url = "/KhenThuong/delete";
    return this._httpService.post(url, model).toPromise();
  }
  async deletes(model: any) {
    const url = "/KhenThuong/deletes";
    return this._httpService.post(url, model).toPromise();
  }

  async filter(criteria: Criteria) {
    const url = "/KhenThuong/filter";
    const resp = await this._httpService.post(url, criteria).toPromise();
    return resp.data;
  }
}
