import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DonVi } from '../../models/don-vi.model';
import { DonViService } from '../../services/don-vi.service';
import { HttpService } from '../../services/common/http.service';
import { LoaiDonVi } from '../../models/loai-don-vi.model';
import { LoaiDonViService } from '../../services/loai-don-vi.service';

@Component({
  selector: 'app-unit-type',
  templateUrl: './unit-type.component.html',
  styleUrls: ['./unit-type.component.scss'],
  //providers: [DonViService]
})
export class UnitTypeComponent implements OnInit {
  public detailData: LoaiDonVi;
  constructor(
    public loaiDonViService: LoaiDonViService,
    public dialogRef: MatDialogRef<UnitTypeComponent>
  ) {
    this.detailData = new LoaiDonVi();
  }

  ngOnInit() {
  }
  async save(): Promise<void> {
    //save data
    const resq = await this.loaiDonViService.saveData(this.detailData);
    if (resq.isSuccess()) {
     this.dialogRef.close();
    }
  }

}
