import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AdminModule } from './modules/admin/admin.module';
import { HttpClientModule } from '@angular/common/http';
import { HttpService } from './modules/admin/services/common/http.service';
import { AuthService } from './modules/admin/services/common/auth.service';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgSelect2Module } from 'ng-select2';
import { FormsModule } from "@angular/forms";

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    AdminModule,
    MatDatepickerModule,
    FormsModule,
    NgSelect2Module
  ],
  providers: [
    HttpService,
    AuthService,
    HttpClientModule
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
