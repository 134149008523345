import { BaseCriteria } from 'app/modules/admin/models/common/base.criteria';

export class QuyetDinhKhenThuongCriteria extends BaseCriteria {
    maDoiTuong = -1;
    maDanhXung = -1;
    maChucVu = -1;
    maLoaiCanBo = -1;
    maDonVi = -1;
    maTinh = '-1';
    maHuyen = '-1';
    maXa = '-1';
    namKhen = -1;
    maCap = -1;
    maHinhThuc = -1;
    maMucGiaiThuong = -1;
    khenthuong = -1;
    searchText: string;
}
