import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { PageDTO, DEFAULT_PAGE_LIMIT } from "app/modules/admin/models/common/page.model";
@Component({
    selector: "app-paging-list",
    templateUrl: "./paging-list.component.html",
    styleUrls: ["./paging-list.component.scss"]
})
export class PagingListComponent implements OnInit {

    public page: PageDTO;
    public currentPageIndex = 0;
    public fromNumber = 0;
    public toNumber = 0;
    public totalPageCount = 0;

    public pageIndexes: Array<number>;

    @Output()
    public change = new EventEmitter<number>();

    @Input("page")
    set setPage(value: PageDTO) {
        this.page = value;
        this.setDefault();
    }

    @Input()
    public range = 2;

    constructor() { }


    setDefault() {
        if (!this.page) {
            this.page = {
                limit: 15,
                totalItems: 0,
                offset: 0
            } as PageDTO;
        } else {
            if (!this.page.limit) {
                this.page.limit = DEFAULT_PAGE_LIMIT;
            }
            if (!this.page.totalItems) {
                this.page.totalItems = 0;
            }
            if (!this.page.offset) {
                this.page.offset = 0;
            }
        }

        this.fromNumber = this.page.offset + 1;
        this.toNumber = this.page.offset + this.page.limit;
        if (this.toNumber > this.page.totalItems) {
            this.toNumber = this.page.totalItems;
        }

        this.currentPageIndex = Math.floor(this.page.offset / this.page.limit) + 1;
        this.totalPageCount = Math.ceil(this.page.totalItems / this.page.limit);

        this.pageIndexes = new Array<number>();

        for (let i = this.currentPageIndex - 2; i <= this.currentPageIndex + 2; i++) {
            if (i > 0 && i <= this.totalPageCount) {
                this.pageIndexes.push(i);
            }
        }
    }

    goToPage(pageIndex) {
        // this.setDefault();
        if (!pageIndex || pageIndex < 1) {
            pageIndex = 1;
        }
        this.page.offset = (pageIndex - 1) * this.page.limit;
        this.change.emit(this.page.offset);
    }

    ngOnInit() {
        this.setDefault();
    }

}
