<div class="page-wrapper">
  <!-- ============================================================== -->
  <!-- Bread crumb and right sidebar toggle -->
  <!-- ============================================================== -->
  <div class="page-breadcrumb">
    <div class="row">
      <div class="col-12 d-flex no-block align-items-center">
        <h4 class="page-title">Chi tiết thi đua khen thưởng</h4>
        <div class="ml-auto text-right">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="#">Home</a></li>
              <li class="breadcrumb-item active" aria-current="page">Thi đua khen thưởng</li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
  </div>
  <!-- ============================================================== -->
  <!-- End Bread crumb and right sidebar toggle -->
  <!-- ============================================================== -->
  <!-- ============================================================== -->
  <!-- Container fluid  -->
  <!-- ============================================================== -->
  <div class="container-fluid">
    <!-- ============================================================== -->
    <!-- Start Page Content -->
    <!-- ============================================================== -->
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <form class="form-horizontal">
            <div class="card-body">
              <h4 class="card-title">Đối tượng khen thưởng</h4>
              <div class="form-group row">
                <label for="fname" class="col-sm-3 text-right control-label col-form-label">Danh xưng</label>
                <div class="col-sm-9">
                  <select class="select2 form-control custom-select" style="width: 100%; height:36px;">
                    <option>Chọn</option>
                    <option>Ông</option>
                    <option>Bà</option>
                    <option>Gia đình ông</option>
                    <option>Già đỉnh bà</option>
                    <option>Gia đình ông bà</option>
                    <option>Tập thể</option>
                    <option>Đối tượng khác</option>
                  </select>
                </div>
              </div>
              <div class="form-group row">
                <label for="lname" class="col-sm-3 text-right control-label col-form-label">Tên tập thể/Cá nhân</label>
                <div class="col-sm-9">
                  <input type="text" class="form-control" id="lname" value="Nguyễn Thanh Dương" placeholder="Nhập Họ và tên">
                </div>
              </div>
              <div class="form-group row">
                <label for="lname" class="col-sm-3 text-right control-label col-form-label">Chức danh</label>
                <div class="col-sm-9">
                  <select class="select2 form-control custom-select" style="width: 100%; height:36px;">
                    <option>Chọn</option>
                    <option>Lãnh đạo cấp Bộ, ngành, tỉnh và đoàn thể ở trung ương và tương đương trở lên</option>
                    <option>Doanh nhân</option>
                    <option>Đối tượng khác</option>
                    <option>Lãnh đạo cấp Vụ, sở, huyện, ban, ngành và tương đương</option>
                    <option>Người trực tiếp công tác, lao động, sản xuất và chiến đấu</option>
                  </select>
                </div>
              </div>
              <div class="form-group row">
                <label for="lname" class="col-sm-3 text-right control-label col-form-label">Chức vụ</label>
                <div class="col-sm-9">
                  <input type="text" class="form-control" id="lname" value="Phó chủ tịch" placeholder="Nhập Chức vụ">
                </div>
              </div>
              <div class="form-group row">
                <label for="lname" class="col-sm-3 text-right control-label col-form-label">Địa chỉ - Nơi công tác</label>
                <div class="col-sm-9">
                  <input type="text" class="form-control" id="lname" value=" UBND tỉnh Lào Cai" placeholder="Nhập Địa chỉ - Nơi công tác">
                </div>
              </div>

              <div class="form-group row">
                <label for="lname" class="col-sm-3 text-right control-label col-form-label">Khen thưởng</label>
                <div class="col-sm-9">
                  <select class="select2 form-control custom-select" style="width: 100%; height:36px;">
                    <option>Chọn</option>
                    <option>Huân chương Lao động hạng Nhất</option>
                    <option>Huân chương Lao động hạng Nhì</option>
                    <option>Huân chương Lao động hạng Ba</option>
                  </select>
                </div>
              </div>

              <div class="form-group row">
                <label for="lname" class="col-sm-3 text-right control-label col-form-label">Hoạt động</label>
                <div class="col-sm-9">
                  <select class="select2 form-control custom-select" style="width: 100%; height:36px;">
                    <option>Chọn</option>
                    <option>Quản lý Nhà nước</option>
                    <option>Y tế</option>
                    <option>Tôn giáo - Tín ngưỡng</option>
                  </select>
                </div>
              </div>
              <div class="form-group row">
                <label for="lname" class="col-sm-3 text-right control-label col-form-label">Hình thức khen tặng</label>
                <div class="col-sm-9">
                  <select class="select2 form-control custom-select" style="width: 100%; height:36px;">
                    <option>Chọn</option>
                    <option>Tặng</option>
                    <option>Thu hồi</option>
                    <option>Truy tặng</option>
                  </select>
                </div>
              </div>
              <div class="form-group row">
                <label for="lname" class="col-sm-3 text-right control-label col-form-label">Thành tích</label>
                <div class="col-sm-9">
                  <input type="text" class="form-control" id="lname" value="Đã có nhiều thành tích xuất sắc, góp phần vào sự nghiệp xây dựng chủ nghĩa xã hội và bảo vệ Tổ quốc"
                    placeholder="Nhập Thành tích">
                </div>
              </div>



            </div>
            <div class="border-top">
              <div class="card-body">
                <button type="button" class="btn btn-primary">Submit</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- ============================================================== -->
  <!-- End Container fluid  -->
  <!-- ============================================================== -->
  <!-- ============================================================== -->
  <!-- footer -->
  <!-- ============================================================== -->
  <footer class="footer text-center">
    All Rights Reserved by Matrix-admin. Designed and Developed by <a href="https://wrappixel.com">WrapPixel</a>.
  </footer>
  <!-- ============================================================== -->
  <!-- End footer -->
  <!-- ============================================================== -->
</div>
