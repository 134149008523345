import { Component, OnInit, EventEmitter, Input } from "@angular/core";
import { PageDTO } from "app/modules/admin/models/common/page.model";

@Component({
    selector: "app-paging-count",
    templateUrl: "./paging-count.component.html",
    styleUrls: ["./paging-count.component.scss"]
})
export class PagingCountComponent implements OnInit {

    @Input()
    public page: PageDTO;

    // @Input("page")
    // set setPage(value: PageDTO) {
    //     this.page = value;
    //     this.setDefault();
    // }

    constructor() { }

    ngOnInit() {
    }

}
