import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { LinhVucHoatDong } from '../../models/linh-vuc-hoat-dong.model';
import { LinhVucHoatDongService } from '../../services/linh-vuc-hoat-dong.service';

@Component({
  selector: 'app-field-of-activity',
  templateUrl: './field-of-activity.component.html',
  styleUrls: ['./field-of-activity.component.scss']
})
export class FieldOfActivityComponent implements OnInit {

  public detailData: LinhVucHoatDong;
  constructor(
    public dialogRef: MatDialogRef<FieldOfActivityComponent>,
    public linhVucHoatDongService: LinhVucHoatDongService,
  ) {
    this.detailData = new LinhVucHoatDong();
  }

  ngOnInit() {
  }
  async save(): Promise<void> {
    // save data
    const resq = await this.linhVucHoatDongService.saveData(this.detailData);
    if (resq.isSuccess()) {
      this.dialogRef.close();
    }
  }

}
