<div class="page-wrapper">
  <div class="container-fluid">
    <!-- ============================================================== -->
    <!-- Start Page Content -->
    <!-- ============================================================== -->
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">
              <button
                *ngIf="showAction"
                type="button"
                class="btn btn-primary float-right"
                (click)="addDecisionToReward()"
              >
                <i class="fas fa-id-card"></i> Thêm mới
              </button>
              <button
                type="button"
                class="btn btn-success float-right"
                (click)="onFilter()"
              >
                <i class="fas fa-filter"></i>
                Lọc
              </button>
              <button
                (click)="deleteList()"
                *ngIf="showAction"
                type="button"
                class="btn btn-danger float-right"
              >
                <i class="fas fa-check-square"></i> Xóa
              </button>
              &nbsp;
              <button
                type="button"
                class="btn btn-success float-right"
                (click)="onExport()"
              >
                <i class="fas fa-download"></i> Xuất file
              </button>
              <button
                *ngIf="showAction"
                type="button"
                class="btn btn-instagram float-right"
                (click)="onExportTemplate()"
              >
                <i class="fas fa-prescription-bottle"></i> Xuất template
              </button>
              <button
                *ngIf="showAction"
                type="button"
                class="btn btn-facebook float-right"
                (click)="onImportTemplate()"
              >
                <i class="fas fa-warehouse"></i> Nhập dữ liệu
              </button>
              <div *ngIf="showAction" class="col-2 float-right">
                <input
                  name="fileupload"
                  type="file"
                  class="custom-file-input"
                  id="validatedCustomFile"
                  (change)="onFileChange($event)"
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                />
                <label class="custom-file-label" for="validatedCustomFile"
                  >Chọn tập tin...</label
                >
              </div>
            </h5>
            <br />
            <!-- <div class="col-12"> -->
            <div class="row">
              <!-- doi tuong -->
              <div class="col-2">
                <ng-select2
                  [data]="doiTuongOption"
                  allowClear="true"
                  placeholder="Chọn đối tượng"
                  (valueChanged)="changeFilter('maDoiTuong', $event)"
                >
                  <!-- <option></option>
                  <option *ngFor="let opt of doiTuongOption" [ngValue]="opt.id">
                    {{ opt.text }}
                  </option> -->
                </ng-select2>
              </div>
              <!-- danh xung -->
              <div class="col-2">
                <ng-select2
                  [data]="danhXungOption"
                  allowClear="true"
                  placeholder="Chọn danh xưng"
                  (valueChanged)="changeFilter('maDanhXung', $event)"
                >
                  <!-- <option></option>
                  <option *ngFor="let opt of danhXungOption" [ngValue]="opt.id">
                    {{ opt.text }}
                  </option> -->
                </ng-select2>
                <!-- <ng-select2 [data]="danhXungOption" [cssImport]="false" [options]="select2Option"
                                    (valueChanged)="changeFilter('maDanhXung', $event)">
                                </ng-select2> -->
                <!-- <select id="danhxungf" [(ngModel)]="filter.maDanhXung"
                                    class="select2 form-control custom-select" style="width: 100%; height:36px;">
                                    <option value="-1">Chọn danh xưng</option>
                                    <option *ngFor="let c of danhXung" [ngValue]="c.maDanhXung">{{c.tenDanhXung}}
                                    </option>
                                </select> -->
              </div>
              <!-- chuc vụ -->
              <div class="col-2">
                <select
                  id="chucvuf"
                  [(ngModel)]="filter.maChucVu"
                  class="select2 form-control custom-select"
                  style="width: 100%; height: 36px"
                >
                  <option value="-1">Chọn chức vụ</option>
                  <option *ngFor="let c of chucVu" [ngValue]="c.maChucVu">
                    {{ c.tenChucVu }}
                  </option>
                </select>
              </div>

              <!-- loai can bo -->
              <div class="col-2">
                <select
                  id="loaicanbof"
                  [(ngModel)]="filter.maLoaiCanBo"
                  class="select2 form-control custom-select"
                  style="width: 100%; height: 36px"
                >
                  <option value="-1">Chọn loại cán bộ</option>
                  <option *ngFor="let c of loaiCanBo" [ngValue]="c.maLoaiCanBo">
                    {{ c.tenLoaiCanBo }}
                  </option>
                </select>
              </div>
              <!-- don vi -->
              <div class="col-2">
                <select
                  id="donvif"
                  [(ngModel)]="filter.maDonVi"
                  class="select2 form-control custom-select"
                  style="width: 100%; height: 36px"
                >
                  <option value="-1">Chọn đơn vị</option>
                  <option *ngFor="let c of donVi" [ngValue]="c.maDonVi">
                    {{ c.tenDonVi }}
                  </option>
                </select>
              </div>

              <!-- tinh -->
              <div class="col-2">
                <select
                  id="tinhf"
                  [ngModel]="filter.maTinh"
                  (ngModelChange)="onChangeMaTinh($event)"
                  class="select2 form-control custom-select"
                  style="width: 100%; height: 36px"
                >
                  <option value="-1">Chọn tinh</option>
                  <option *ngFor="let c of tinh" [ngValue]="c.maTinh">
                    {{ c.tenTinh }}
                  </option>
                </select>
              </div>
              <!-- huyen -->
              <div class="col-2">
                <select
                  id="huyenf"
                  [(ngModel)]="filter.maHuyen"
                  (ngModelChange)="onChangeMaHuyen($event)"
                  class="select2 form-control custom-select"
                  style="width: 100%; height: 36px"
                >
                  <option value="-1">Chọn huyện</option>
                  <option *ngFor="let c of huyen" [ngValue]="c.maHuyen">
                    {{ c.tenHuyen }}
                  </option>
                </select>
              </div>

              <!-- xa -->
              <div class="col-2">
                <select
                  id="xaf"
                  [(ngModel)]="filter.maXa"
                  class="select2 form-control custom-select"
                  style="width: 100%; height: 36px"
                >
                  <option value="-1">Chọn xã</option>
                  <option *ngFor="let c of xa" [ngValue]="c.maXa">
                    {{ c.tenXa }}
                  </option>
                </select>
              </div>
              <!-- nam khen -->
              <div class="col-2">
                <input
                  class="form-control"
                  placeholder="Nhặp năm khen thưởng"
                  [(ngModel)]="filter.namKhen"
                />
              </div>
              <!-- cap khen thuong -->
              <div class="col-2">
                <select
                  id="camkhenthuongf"
                  [(ngModel)]="filter.maCap"
                  class="select2 form-control custom-select"
                  style="width: 100%; height: 36px"
                >
                  <option value="-1">Chọn cấp khen thưởng</option>
                  <option *ngFor="let c of capKhenThuong" [ngValue]="c.maCap">
                    {{ c.tenCap }}
                  </option>
                </select>
              </div>
              <!-- hinh thuc -->
              <div class="col-2">
                <select
                  id="hinhthucf"
                  [(ngModel)]="filter.maHinhThuc"
                  class="select2 form-control custom-select"
                  style="width: 100%; height: 36px"
                >
                  <option value="-1">Chọn hình thức</option>
                  <option
                    *ngFor="let c of hinhThucKhenThuong"
                    [ngValue]="c.maHinhThuc"
                  >
                    {{ c.tenHinhThuc }}
                  </option>
                </select>
              </div>
              <!-- muc giai thuong -->
              <div class="col-2">
                <select
                  id="mucgiaithuongf"
                  [(ngModel)]="filter.maHinhThuc"
                  class="select2 form-control custom-select"
                  style="width: 100%; height: 36px"
                >
                  <option value="-1">Chọn mục giải thưởng</option>
                  <option
                    *ngFor="let c of mucGiaiThuong"
                    [ngValue]="c.maMucGiaiThuong"
                  >
                    {{ c.tenMucGiaiThuong }}
                  </option>
                </select>
              </div>

              <!-- danh hieu -->
              <div class="col-2">
                <select
                  id="khenthuongF"
                  [(ngModel)]="filter.khenthuong"
                  class="select2 form-control custom-select"
                  style="width: 100%; height: 36px"
                >
                  <option value="-1">Chọn mục danh hiệu</option>
                  <option
                    *ngFor="let c of khenThuong"
                    [ngValue]="c.maKhenThuong"
                  >
                    {{ c.tenKhenThuong }}
                  </option>
                </select>
              </div>

              <div class="col-10">
                <input
                  (keyup.enter)="reloadData()"
                  type="text"
                  class="form-control"
                  name="lname"
                  [(ngModel)]="filter.searchText"
                  placeholder="Tìm kiếm tập thể/ cá nhân, ghi chú, số định danh, số quyết định..."
                />
              </div>
            </div>
            <!-- </div> -->
            <br />
            <br />
            <div class="table-responsive">
              <table
                id="zero_config"
                class="table table-striped table-bordered"
              >
                <thead>
                  <tr>
                    <th class="align-middle">
                      <!-- <button (click)="checkAll()" type="button"
                                                class="btn btn-danger"><i class="fas fa-check-square"></i> Xóa</button> -->
                      <input
                        type="checkbox"
                        [checked]="selectAllRow"
                        (change)="checkAll()"
                      />
                    </th>
                    <th class="align-middle">STT</th>
                    <th class="align-middle">Đối tượng</th>
                    <th class="align-middle">Danh xưng</th>
                    <th class="align-middle">Tập thể/Cá nhân</th>
                    <th class="align-middle">Địa chỉ</th>
                    <th class="align-middle">Chức vụ</th>
                    <th class="align-middle">Loại CB</th>
                    <th class="align-middle">Đơn vị</th>
                    <th class="align-middle">Tỉnh</th>
                    <th class="align-middle">Huyện</th>
                    <th class="align-middle">Xã</th>
                    <th class="align-middle">Số QĐ</th>
                    <th class="align-middle">Ngày QĐ</th>
                    <th class="align-middle">Năm khen</th>
                    <th class="align-middle">Cấp KT</th>
                    <th class="align-middle">Hình thức</th>
                    <th class="align-middle">Danh hiệu</th>
                    <th class="align-middle">Mục giải thưởng</th>
                    <th class="align-middle">Ghi chú</th>
                    <th class="align-middle">#</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let c of detailData; index as i">
                    <td>
                      <input
                        type="checkbox"
                        [checked]="c.selected"
                        (change)="changeCheck(i)"
                      />
                    </td>
                    <td>{{ i + 1 + filter.page.offset }}</td>
                    <td>{{ c.tenDoiTuong }}</td>
                    <td>{{ c.tenDanhXung }}</td>
                    <td>{{ c.ten }}</td>
                    <td>{{ c.diaChi }}</td>
                    <td>{{ c.tenChucVu }}</td>
                    <td>{{ c.tenLoaiCanBo }}</td>
                    <td>{{ c.tenDonVi }}</td>
                    <td>{{ c.tenTinh }}</td>
                    <td>{{ c.tenHuyen }}</td>
                    <td>{{ c.tenXa }}</td>
                    <td>
                      <a
                        *ngIf="c.attachment; else attachmentElse"
                        href="{{ pathDownLoad + c.attachment }}"
                        target="_blank"
                        >{{ c.soQuyetDinh }}</a
                      >

                      <ng-template #attachmentElse>
                        {{ c.soQuyetDinh }}
                      </ng-template>
                    </td>
                    <td>{{ c.ngayQuyetDinh | date: "dd/MM/yyyy" }}</td>
                    <td>{{ c.namKhen }}</td>
                    <td>{{ c.tenCap }}</td>
                    <td>{{ c.tenHinhThuc }}</td>
                    <td>{{ c.tenKhenThuong }}</td>
                    <td>{{ c.tenMucGiaiThuong }}</td>
                    <td>{{ c.ghiChu }}</td>
                    <td>
                      <a
                        *ngIf="showAction"
                        routerLinkActive="active"
                        (click)="editDecisionToReward(c)"
                        href="javascript:;"
                        ><i class="fas fa-edit"></i
                      ></a>
                      <!-- <button type="button" class="btn btn-default" (click)="editDecisionToReward(c)"></button> -->
                      <!-- <button type="button" class="btn btn-default" (click)="deleteDecisionToReward(c)"><i
                                                    class="fas fa-eraser"></i></button> -->
                      <a
                        *ngIf="showAction"
                        routerLinkActive="active"
                        (click)="deleteDecisionToReward(c)"
                        href="javascript:;"
                        ><i class="fas fa-eraser"></i
                      ></a>
                      <a
                        routerLinkActive="active"
                        (click)="viewDetail(c)"
                        href="javascript:;"
                        ><i class="fas fa-align-justify"></i
                      ></a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <app-paging-count [page]="filter.page"></app-paging-count>
            <app-paging-list
              [page]="filter.page"
              (change)="changeFilterPage($event)"
            ></app-paging-list>
          </div>
        </div>
      </div>
    </div>
    <!-- ============================================================== -->
    <!-- End PAge Content -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- Right sidebar -->
    <!-- ============================================================== -->
    <!-- .right-sidebar -->
    <!-- ============================================================== -->
    <!-- End Right sidebar -->
    <!-- ============================================================== -->
  </div>
</div>
